import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {TSegmentOrchestratorMessageNamespace} from '@Logger/Types/TSegmentOrchestratorMessageNamespace';
import {renderSegmentLoggingName} from '@Shared/Util/renderSegmentLoggingName';

import {SegmentOrchestratorMessageId} from './SegmentOrchestratorMessageId';

const segmentOrchestratorMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [SegmentOrchestratorMessageId._000_MAX_OVERLAPPING_REQUESTS_LIMIT_REACHED]: (): string =>
            'Maximum overlapping requests limit reached',
        [SegmentOrchestratorMessageId._001_REQUESTING_NEXT_SEGMENT_OF_VARIANT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
            startTimeSeconds: number,
            endTimeSeconds: number,
        ): string =>
            `Requesting next segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}, for time range ${startTimeSeconds}s-${endTimeSeconds}s`,
        [SegmentOrchestratorMessageId._002_REMOVING_BUFFERED_RANGE]: (start: number, end: number): string =>
            `Removing buffered range from ${start}s to ${end}s`,
        [SegmentOrchestratorMessageId._003_BUFFER_AHEAD_GOAL_MET]: (): string => 'Buffer ahead goal met',
        [SegmentOrchestratorMessageId._005_IGNORING_LAST_REQUESTED_SEGMENT]: (
            segmentIndex: number,
            segmentRangeIndex: number,
        ): string =>
            `Ignoring segment: ${segmentIndex}, range: ${segmentRangeIndex} matching the previous buffering segment`,
        [SegmentOrchestratorMessageId._006_POSTPONED_BUFFER_AHEAD_OF_SISTER]: (): string =>
            'Segment request on hold as buffer is ahead of sister',
        [SegmentOrchestratorMessageId._007_OVERLAPPING_REQUESTS_POSTPONED_UNHEALTHY_BUFFER]: (): string =>
            'Overlapping requests postponed due to unhealthy buffer',
        [SegmentOrchestratorMessageId._009_NO_SEGMENT_REQUESTED_NONE_FOUND]: (location: string): string =>
            `No segment requested due to none found in ${location}`,
    },
    [MessageType.WARNING]: {
        [SegmentOrchestratorMessageId._004_PLAYHEAD_OUTWITH_SEEK_RANGE]: (): string =>
            'The playhead is behind all playable ranges found in the manifest',
        [SegmentOrchestratorMessageId._008_NO_SEGMENT_REQUESTED_INACTIVE_VARIANT]: (): string =>
            'No segment requested due to inactive variant',
    },
    [MessageType.ERROR]: {},
};

export type TSegmentOrchestratorMessages = TLoggerMessages<
    TSegmentOrchestratorMessageNamespace,
    typeof segmentOrchestratorMessages
>;

export {segmentOrchestratorMessages};
