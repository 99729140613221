import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {DepoMessageId} from './DepoMessageId';

const depoMessages = {
    [MessageType.INFO]: {
        [DepoMessageId._000_STATE_UPDATE]: (
            storeName: string,
            actionName: string,
            wasBatchUpdate: boolean,
            wasLinkedUpdate: boolean,
            fallback: string,
        ): string => {
            if (fallback) return fallback;

            let msg = `Store "${storeName}" updated`;

            if (wasLinkedUpdate) msg += ' in reaction to linked store update';
            if (actionName) msg += ` by action \`${actionName}()\``;
            if (wasBatchUpdate) msg += ' as part of batch';

            return msg;
        },
    },
    [MessageType.LOG]: {},
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TDepoMessages = TLoggerMessages<MessageNamespace._001_DEPO, typeof depoMessages>;

export {depoMessages};
