import {FeaturePersistedBandwidthEstimateMessageId} from '@Features/PersistedBandwidthEstimate/Constants/FeaturePersistedBandwidthEstimateMessageId';
import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import type {TDeepPartial} from '@Shared/Types/TDeepPartial';
import type {createLocalStorageStore} from '@State/Stores/LocalStorage/createLocalStorageStore';
import type {Metrics} from '@State/Stores/Metrics/Models/Metrics';

const readInitialMetricsValueFromLocalStorage = (
    localStorageStore: ReturnType<typeof createLocalStorageStore>,
): TDeepPartial<Metrics> => {
    const {mostRecentBandwidthEstimate} = localStorageStore.value;

    if (
        mostRecentBandwidthEstimate <= 0 ||
        isNaN(mostRecentBandwidthEstimate) ||
        mostRecentBandwidthEstimate === Infinity
    ) {
        // All of these values are either meaningless or will result in a broken/NaN
        // EWMA estimate that cannot be corrected
        return {};
    }

    logger.log(
        MessageNamespace._024_FEATURE_PERSISTED_BANDWIDTH_ESTIMATE,
        FeaturePersistedBandwidthEstimateMessageId._001_READING_BANDWIDTH_ESTIMATE_FROM_LOCAL_STORAGE,
        mostRecentBandwidthEstimate,
    );

    return {
        network: {
            timeBasedBandwidthEwmaFast: mostRecentBandwidthEstimate,
            timeBasedBandwidthEwmaSlow: mostRecentBandwidthEstimate,
        },
    };
};

export {readInitialMetricsValueFromLocalStorage};
