import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureLocalStorageMessageId} from './FeatureLocalStorageMessageId';

const featureLocalStorageMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [FeatureLocalStorageMessageId._002_DISABLED]: (): string => '`localStorage` is not available',
    },
    [MessageType.WARNING]: {
        [FeatureLocalStorageMessageId._000_READ_FAILURE]: (key: string): string =>
            `Could not read "${key}" from \`localStorage\``,
        [FeatureLocalStorageMessageId._001_WRITE_FAILURE]: (key: string): string =>
            `Could not write "${key}" to \`localStorage\``,
    },
    [MessageType.ERROR]: {},
};

export type TFeatureLocalStorageMessages = TLoggerMessages<
    MessageNamespace._029_FEATURE_LOCAL_STORAGE,
    typeof featureLocalStorageMessages
>;

export {featureLocalStorageMessages};
