import type {TAdaptivePresentationDelayStatus} from '@AdaptivePresentationDelayManager/Const/AdaptivePresentationDelayStatus';
import {AdaptivePresentationDelayStatus} from '@AdaptivePresentationDelayManager/Const/AdaptivePresentationDelayStatus';
import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {TStrictExclude} from '@Shared/Types/TStrictExclude';
import {Playback} from '@State/Stores/Metrics/Models/Playback';

import {FeatureAdaptivePresentationDelayMessageId} from './FeatureAdaptivePresentationDelayMessageId';

type TVariablePlaybackRateStrategyOptions = {
    distanceToAvailabilityWindowEndTimeSeconds: number;
    presentationDelaySeconds: number;
    playbackRate: number;
    status: TStrictExclude<TAdaptivePresentationDelayStatus, 'DISABLED'>;
};

const adaptivePresentationDelayMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [FeatureAdaptivePresentationDelayMessageId._000_VARIABLE_PLAYBACK_RATE_STRATEGY]: ({
            distanceToAvailabilityWindowEndTimeSeconds,
            status,
            playbackRate,
            presentationDelaySeconds,
        }: TVariablePlaybackRateStrategyOptions): string =>
            ({
                [AdaptivePresentationDelayStatus.IDLE]: `Variable playback rate strategy was enabled`,
                [AdaptivePresentationDelayStatus.INCREASING]:
                    `The user is beyond the presentation delay so setting playback rate to ${playbackRate} ` +
                    `to increase the distance to availability window end ` +
                    `${distanceToAvailabilityWindowEndTimeSeconds}s and to reach ` +
                    `the presentation delay ${presentationDelaySeconds}s`,
                [AdaptivePresentationDelayStatus.DECREASING]:
                    `The user is beyond the presentation delay so setting playback rate to ${playbackRate} ` +
                    `to decrease the distance to availability window end ` +
                    `${distanceToAvailabilityWindowEndTimeSeconds}s and to reach ` +
                    `the presentation delay ${presentationDelaySeconds}s`,
                [AdaptivePresentationDelayStatus.SYNCHRONISED]:
                    `The user sits at the presentation delay ${presentationDelaySeconds}s ` +
                    `so setting playback back to normal (i.e. ${playbackRate}), ` +
                    `current distance to availability window end is ${distanceToAvailabilityWindowEndTimeSeconds}s`,
            })[status],
        [FeatureAdaptivePresentationDelayMessageId._001_RESET_PLAYBACK_RATE_ON_DISABLE]: (
            previousPlaybackRate: number,
        ): string =>
            `The Adaptive Presentation Delay feature is disabled so ` +
            `setting playback rate from ${previousPlaybackRate} to ${Playback.DEFAULT_PLAYBACK_RATE}`,
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TFeatureAdaptivePresentationDelayMessages = TLoggerMessages<
    MessageNamespace._033_ADAPTIVE_PRESENTATION_DELAY,
    typeof adaptivePresentationDelayMessages
>;

export {adaptivePresentationDelayMessages};
