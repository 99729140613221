import type {ConfigAbr} from '../../Config/Models/ConfigAbr';
import {BufferHealthStatus} from '../Constants/BufferHealthStatus';

const bufferHealthStatusMachine = (
    prevBufferHealthStatus: BufferHealthStatus,
    combinedBufferDuration: number,
    configAbr: ConfigAbr,
    bufferAheadGoalSeconds: number,
): BufferHealthStatus => {
    const {healthyBufferFraction, unhealthyBufferFraction, criticalBufferFraction} = configAbr;

    const healthyBufferThresholdSeconds = bufferAheadGoalSeconds * healthyBufferFraction;
    const unhealthyBufferThresholdSeconds = bufferAheadGoalSeconds * unhealthyBufferFraction;
    const criticalBufferThresholdSeconds = bufferAheadGoalSeconds * criticalBufferFraction;

    switch (prevBufferHealthStatus) {
        case BufferHealthStatus.EMPTY:
            if (combinedBufferDuration >= healthyBufferThresholdSeconds) {
                return BufferHealthStatus.HEALTHY;
            } else if (combinedBufferDuration >= unhealthyBufferThresholdSeconds) {
                return BufferHealthStatus.UNHEALTHY;
            } else if (combinedBufferDuration >= 0) {
                return BufferHealthStatus.CRITICAL;
            }

            return prevBufferHealthStatus;
        case BufferHealthStatus.CRITICAL:
            if (combinedBufferDuration >= healthyBufferThresholdSeconds) {
                return BufferHealthStatus.HEALTHY;
            } else if (combinedBufferDuration >= unhealthyBufferThresholdSeconds) {
                return BufferHealthStatus.UNHEALTHY;
            } else if (combinedBufferDuration === 0) {
                return BufferHealthStatus.EMPTY;
            }

            return prevBufferHealthStatus;

        case BufferHealthStatus.HEALTHY:
            if (combinedBufferDuration === 0) {
                return BufferHealthStatus.EMPTY;
            } else if (combinedBufferDuration < criticalBufferThresholdSeconds) {
                return BufferHealthStatus.CRITICAL;
            } else if (combinedBufferDuration < unhealthyBufferThresholdSeconds) {
                return BufferHealthStatus.UNHEALTHY;
            }

            return prevBufferHealthStatus;
        case BufferHealthStatus.UNHEALTHY:
            if (combinedBufferDuration >= healthyBufferThresholdSeconds) {
                return BufferHealthStatus.HEALTHY;
            } else if (combinedBufferDuration === 0) {
                return BufferHealthStatus.EMPTY;
            } else if (combinedBufferDuration < criticalBufferThresholdSeconds) {
                return BufferHealthStatus.CRITICAL;
            }

            return prevBufferHealthStatus;
        default:
            return prevBufferHealthStatus;
    }
};

export {bufferHealthStatusMachine};
