import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureLiveLatencyMeasurementMessageId} from './FeatureLiveLatencyMeasurementMessageId';

const liveLatencyMeasurementMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [FeatureLiveLatencyMeasurementMessageId._000_UTC_TIMING_URL_READY_FOR_TIME_SYNCHRONISATION]: (
            url: string,
        ): string =>
            `UTC Timing URL ${url} has been parsed from manifest and available for time server synchronisation`,
        [FeatureLiveLatencyMeasurementMessageId._001_DEVICE_CLOCK_OFFSET_ESTIMATED]: (
            joinedSamples: string,
            finalMs: number,
        ) => `Device clock offset estimated to be ${finalMs} from samples of ${joinedSamples}`,
        [FeatureLiveLatencyMeasurementMessageId._007_AVAILABILITY_TIME_OFFSET_CALCULATED]: (
            availabilityTimeOffsetSeconds: number,
        ): string =>
            `Dynamic manifest parsed and availability time offset is calculated as ${availabilityTimeOffsetSeconds}s`,
    },
    [MessageType.WARNING]: {
        [FeatureLiveLatencyMeasurementMessageId._002_MISSING_TIME_TO_FIRST_BYTE]: () =>
            `Could not determine time to first byte`,
        [FeatureLiveLatencyMeasurementMessageId._003_TIMING_RESPONSE_MISSING_TIME_INFO]: (response: string) =>
            `Response from trusted time server was missing timing info (${response})`,
        [FeatureLiveLatencyMeasurementMessageId._004_TOO_MUCH_VARIATION_IN_DEVICE_CLOCK_OFFSET]: (
            joinedSamples: string,
        ) =>
            `Too much variation in estimated device clock offset (${joinedSamples}). ` +
            `Live latency measurement will be unavailable`,
        [FeatureLiveLatencyMeasurementMessageId._005_TIMING_REQUEST_ERROR]: (errText: any): string =>
            `Attempt to determine device clock offset encountered error: ${errText}`,
    },
    [MessageType.ERROR]: {},
};

export type TFeatureLiveLatencyMeasurementMessages = TLoggerMessages<
    MessageNamespace._032_LIVE_LATENCY_MEASUREMENT,
    typeof liveLatencyMeasurementMessages
>;

export {liveLatencyMeasurementMessages};
