import {objectAssign} from './objectAssign';

type TCreateModelOfTypeWithConstructorParameters = <TModel, TArgs extends any[]>(
    Model: new (..._: TArgs) => TModel,
    overrides: Partial<TModel>,
    args: TArgs,
) => TModel;

type TCreateModelOfTypeWithoutConstructorParameters = <TModel>(
    Model: new () => TModel,
    overrides?: Partial<TModel>,
) => TModel;

type TCreateModelOfType = TCreateModelOfTypeWithConstructorParameters & TCreateModelOfTypeWithoutConstructorParameters;

const createModelOfType = (<TModel>(Model: new (..._: any[]) => TModel, overrides: Partial<TModel> = {}, args: any[]) =>
    objectAssign(Array.isArray(args) ? new Model(args) : new Model(), overrides)) as TCreateModelOfType;

export {createModelOfType};
