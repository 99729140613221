function objectAssign<TTarget>(target: TTarget, source: Partial<TTarget>): TTarget;
function objectAssign<TTarget, TSource>(target: TTarget, source: TSource): TSource & TTarget;
function objectAssign<TTarget, TSource1, TSource2>(
    target: TTarget,
    source1: TSource1,
    source2: TSource2,
): TSource1 & TSource2 & TTarget;
function objectAssign<TTarget, TSource1, TSource2, TSource3>(
    target: TTarget,
    source1: TSource1,
    source2: TSource2,
    source3: TSource3,
): TSource1 & TSource2 & TSource3 & TTarget;
function objectAssign(target: object, ...sources: object[]): object {
    return Object.assign(target, ...sources);
}

export {objectAssign};
