import {msToSeconds} from '@dazn/peng-html5-tools-utils';

import {StreamType} from '@Shared/Constants/StreamType';
import {roundTo3DecimalPlaces} from '@Shared/Util/roundTo3DecimalPlaces';
import {roundTo4DecimalPlaces} from '@Shared/Util/roundTo4DecimalPlaces';

import {ConfigAbr} from '../../Config/Models/ConfigAbr';
import {BufferHealthStatus} from '../Constants/BufferHealthStatus';
import {PlayheadStatus} from '../Constants/PlayheadStatus';
import {getSafeSeekRangeEndTimeSeconds} from '../Util/getSafeSeekRangeEndTimeSeconds';
import {getSynchronisedTimeSeconds} from '../Util/getSynchronisedTimeSeconds';

import {BufferData} from './BufferData';

/**
 * Upper bound aims to prevent a very high live latency measurement for unsynced
 * dynamic manifest (aka a non-UNIX-timestamp asset)
 *
 * @see https://livesport.atlassian.net/browse/PLAYASOL-3035
 */
const UPPER_BOUND_LIVE_LATENCY_SECONDS = 60 * 60 * 24 * 7; // a week in seconds

/**
 * Holds the current state of video, audio and text buffers, and all
 * related timing data.
 */

class Timing {
    /**
     * Linked reference to `config.abr`
     */

    public readonly configAbr: ConfigAbr = new ConfigAbr();

    /**
     * Linked reference to `config.behaviour.safeSeekEndOffsetSeconds`
     */

    public readonly configBehaviourSafeSeekEndOffsetSeconds: number = -1;

    /**
     * Linked reference to `manifest.isDynamic`, i.e. Linear or Live content
     */

    public readonly isDynamic: boolean = false;

    /**
     * Linked reference to `manifest.hasDynamicStreamEnded`
     */

    public readonly hasDynamicManifestEnded: boolean = false;

    /**
     * Linked reference to `manifest.seekRangeStartTime`
     */

    public readonly seekRangeStartTimeSeconds = NaN;

    /**
     * Linked reference to `manifest.seekRangeEndTime`
     */

    public readonly seekRangeEndTimeSeconds = NaN;

    /**
     * Linked reference to `manifest.suggestedPresentationDelaySeconds`
     */

    public readonly suggestedPresentationDelaySeconds = NaN;

    /**
     * Presentation delay that is defined through manifest or ABR config
     */
    public get defaultPresentationDelaySeconds(): number {
        return Math.max(
            !isNaN(this.suggestedPresentationDelaySeconds) ? this.suggestedPresentationDelaySeconds : -1,
            this.configAbr.minPresentationDelay,
        );
    }

    /**
     * Desired presentation delay that is configurable via Mercury Public API
     */
    public desiredPresentationDelaySeconds = NaN;

    /**
     * Defines the distance from the availability window end time
     * where the user will be aimed to sit during playback of a dynamic manifest
     *
     * A goal for a Low Latency feature
     */
    public get presentationDelaySeconds(): number {
        if (!isNaN(this.desiredPresentationDelaySeconds)) {
            return this.desiredPresentationDelaySeconds;
        }

        return this.defaultPresentationDelaySeconds;
    }

    public get bufferAheadGoalSeconds(): number {
        if (!this.isDynamic) {
            return this.configAbr.bufferAheadGoalStaticSeconds;
        }

        return this.presentationDelaySeconds;
    }

    /**
     * All buffer data for the audio stream
     */

    public [StreamType.AUDIO]: BufferData = new BufferData();

    /**
     * All buffer data for the video stream
     */

    public [StreamType.VIDEO]: BufferData = new BufferData();

    /**
     * All buffer data for the text stream
     */

    public [StreamType.TEXT]: BufferData = new BufferData();

    /**
     * The current time of the video element.
     */

    public currentTimeSeconds: number = Timing.DEFAULT_CURRENT_TIME_SECONDS;

    /**
     * The delta time between device system clock and trusted time server time, including read latency
     */
    public deviceClockOffsetMs: number = NaN;

    /**
     * Wall clock time (synchronised with trusted time server time for dynamic manifest only)
     */
    public get wallclockTimeSeconds(): number {
        const nowSeconds = this.unsyncedWallclockTimeSeconds;

        if (this.isDynamic && !isNaN(this.deviceClockOffsetMs)) {
            return getSynchronisedTimeSeconds(nowSeconds, this.deviceClockOffsetMs);
        }

        return nowSeconds;
    }

    public get unsyncedWallclockTimeSeconds(): number {
        return roundTo3DecimalPlaces(msToSeconds(Date.now()));
    }

    /**
     * The delta between the player's current media time and the wallclock time.
     *
     * This value can be used to derive the overall latency of DAZN's broadcast pipeline
     */
    public get liveLatencySeconds(): number | undefined {
        // Avoid reporting live latency when time synchronisation hasn't
        // happened due to an inflight request or not available due to a failed
        // request to a trusted time server

        if (this.isDynamic && !isNaN(this.deviceClockOffsetMs)) {
            const syncedValue = roundTo3DecimalPlaces(this.wallclockTimeSeconds - this.currentTimeSeconds);

            if (syncedValue >= UPPER_BOUND_LIVE_LATENCY_SECONDS) {
                return undefined;
            }

            return syncedValue;
        }

        return undefined;
    }

    /**
     * Unsynced version of `liveLatencySeconds`
     *
     * @see {liveLatencySeconds}
     */
    public get unsyncedLiveLatencySeconds(): number | undefined {
        if (this.isDynamic) {
            const unsyncedValue = roundTo3DecimalPlaces(this.unsyncedWallclockTimeSeconds - this.currentTimeSeconds);

            if (unsyncedValue >= UPPER_BOUND_LIVE_LATENCY_SECONDS) {
                return undefined;
            }

            return unsyncedValue;
        }

        return undefined;
    }

    /**
     * Effective approximation of the "headend => CDN" latency which isn't available in the manifest
     * despite `@availabilityTimeOffset` attribute in DASH-IF specification
     *
     * NB: The value is NOT synchronised to `deviceClockOffsetMs`
     */
    public availabilityTimeOffsetSeconds: number = NaN;

    /**
     * The delta between the player's current media time and the availability window end time.
     *
     * NB: The value is NOT synchronised to `deviceClockOffsetMs`
     */
    public get distanceToAvailabilityWindowEndTimeSeconds(): number | undefined {
        if (
            this.isDynamic &&
            typeof this.unsyncedLiveLatencySeconds === 'number' &&
            !isNaN(this.availabilityTimeOffsetSeconds)
        ) {
            // The distance to the availability window end time is
            // calculated as difference between video element current time and
            // wallclock time, which is shifted by availability time offset

            return roundTo3DecimalPlaces(this.unsyncedLiveLatencySeconds - this.availabilityTimeOffsetSeconds);
        }

        return undefined;
    }

    /**
     * A smoothed, rolling average of the frequency of time updates.
     */

    public timeUpdateFrequencySeconds: number = 0;

    /**
     * The maximum gap offset that should be applied.
     */

    public get maxGapOffsetSeconds(): number {
        const {safeGapOffsetSeconds} = this.configAbr;

        // If `safeGapOffsetSeconds` has been set in the profile config,
        // we either use it or time update frequency x2, if set, whichever is higher.
        if (safeGapOffsetSeconds > 0 && this.timeUpdateFrequencySeconds) {
            return Math.max(safeGapOffsetSeconds, this.timeUpdateFrequencySeconds * 2);
        }

        return safeGapOffsetSeconds;
    }

    /**
     * The maximum effective hungry buffer-ahead duration, set dynamically during playback
     * based on available memory in response to `QUOTA_EXCEEDED` exceptions raised from
     * MSE.
     */

    public effectiveHungryBufferAheadDurationSeconds = Infinity;

    /**
     * An enum representing the current state of buffer health, inclusive
     * of small gaps.
     */

    public bufferHealthStatus: BufferHealthStatus = BufferHealthStatus.EMPTY;

    /**
     * An enum representing the position of the playhead in relation to buffered
     * and non-buffered ranges.
     */

    public playheadStatus: PlayheadStatus = PlayheadStatus.OUTSIDE_BUFFER;

    /**
     * The presentation time in seconds, at which the stream should be joined.
     */

    public joinTimeSeconds: number = -1;

    public get isBufferHealthy(): boolean {
        return this.bufferHealthStatus === BufferHealthStatus.HEALTHY;
    }

    public get audioBufferData(): BufferData {
        return this[StreamType.AUDIO];
    }

    public get videoBufferData(): BufferData {
        return this[StreamType.VIDEO];
    }

    public get audioBufferAheadDurationSeconds(): number {
        if (this.currentTimeSeconds < this.audioBufferData.bufferedStartTimeSeconds) return 0;

        return Math.max(0, this.audioBufferData.bufferedEndTimeSeconds - Math.max(0, this.currentTimeSeconds));
    }

    public get audioBufferingAheadDurationSeconds(): number {
        if (this.currentTimeSeconds < this.audioBufferData.bufferedStartTimeSeconds) return 0;

        return Math.max(0, this.audioBufferData.bufferingEndTimeSeconds - Math.max(0, this.currentTimeSeconds));
    }

    public get audioBufferBehindDurationSeconds(): number {
        if (this.currentTimeSeconds > this.audioBufferData.bufferedEndTimeSeconds) return 0;

        return Math.max(0, Math.max(0, this.currentTimeSeconds) - this.audioBufferData.bufferedStartTimeSeconds);
    }

    public get videoBufferAheadDurationSeconds(): number {
        if (this.currentTimeSeconds < this.videoBufferData.bufferedStartTimeSeconds) return 0;

        return Math.max(0, this.videoBufferData.bufferedEndTimeSeconds - Math.max(0, this.currentTimeSeconds));
    }

    public get videoBufferingAheadDurationSeconds(): number {
        if (this.currentTimeSeconds < this.videoBufferData.bufferedStartTimeSeconds) return 0;

        return Math.max(0, this.videoBufferData.bufferingEndTimeSeconds - Math.max(0, this.currentTimeSeconds));
    }

    public get videoBufferBehindDurationSeconds(): number {
        if (this.currentTimeSeconds > this.videoBufferData.bufferedEndTimeSeconds) return 0;

        return Math.max(0, Math.max(0, this.currentTimeSeconds) - this.videoBufferData.bufferedStartTimeSeconds);
    }

    public get combinedBufferAheadDurationSeconds(): number {
        return Math.min(this.audioBufferAheadDurationSeconds, this.videoBufferAheadDurationSeconds);
    }

    public get isInsideBuffer(): boolean {
        return this.playheadStatus === PlayheadStatus.INSIDE_BUFFER;
    }

    public get isInsideBufferOrGap(): boolean {
        return this.playheadStatus !== PlayheadStatus.OUTSIDE_BUFFER;
    }

    public get isInsideGap(): boolean {
        return this.playheadStatus === PlayheadStatus.INSIDE_GAP;
    }

    public get isBehindDvrWindow(): boolean {
        return this.isDynamic && this.currentTimeSeconds < this.seekRangeStartTimeSeconds;
    }

    public get isAtOrBeyondEndOfStream(): boolean {
        if (this.isDynamic && !this.hasDynamicManifestEnded) return false;

        // NB: Streams have been observed to stop/stall some distance before the expected
        // seek range end, so the stall threshold is subtracted.

        return this.timeRemainingSeconds <= this.configAbr.stallThresholdSeconds && this.seekRangeEndTimeSeconds > 0;
    }

    public get isDynamicAndNotEnded(): boolean {
        return this.isDynamic && !this.hasDynamicManifestEnded;
    }

    public get isEnding(): boolean {
        if (this.isDynamic && !this.hasDynamicManifestEnded) return false;

        return (
            this.timeRemainingSeconds <= this.configBehaviourSafeSeekEndOffsetSeconds &&
            this.seekRangeEndTimeSeconds > 0
        );
    }

    public get hasEndedOrIsEnding(): boolean {
        return this.isAtOrBeyondEndOfStream || this.isEnding;
    }

    public get distanceFromSeekRangeEndSeconds(): number {
        return roundTo4DecimalPlaces(this.seekRangeEndTimeSeconds - this.currentTimeSeconds);
    }

    public get distanceFromSeekRangeStartSeconds(): number {
        return roundTo4DecimalPlaces(this.currentTimeSeconds - this.seekRangeStartTimeSeconds);
    }

    public get timeRemainingSeconds(): number {
        return this.distanceFromSeekRangeEndSeconds;
    }

    public get safeSeekRangeEndTimeSeconds(): number {
        return getSafeSeekRangeEndTimeSeconds({
            bufferAheadGoal: this.bufferAheadGoalSeconds,
            isDynamicAndNotEnded: this.isDynamicAndNotEnded,
            minPresentationDelay: this.configAbr.minPresentationDelay,
            safeSeekEndOffsetSeconds: this.configBehaviourSafeSeekEndOffsetSeconds,
            seekRangeEndTimeSeconds: this.seekRangeEndTimeSeconds,
            suggestedPresentationDelaySeconds: this.suggestedPresentationDelaySeconds,
        });
    }

    public static DEFAULT_CURRENT_TIME_SECONDS = -1;
}

export {Timing};
