import type {State, Store} from '@dazn/depo';
import {BehaviorSubject} from 'rxjs';

import type {TDeepPartial} from '@Shared/Types/TDeepPartial';

import {StoreName} from '../../Constants/StoreName';

import {resolveMetricsActions} from './Actions/resolveMetricsActions';
import {Metrics} from './Models/Metrics';
import {populateMetricsLinksFromConfig} from './Util/populateMetricsLinksFromConfig';
import {populateMetricsLinksFromManifest} from './Util/populateMetricsLinksFromManifest';

const createMetricsStore = (
    state: State,
    initialValue: TDeepPartial<Metrics> = {},
): Store<Metrics, typeof resolveMetricsActions, BehaviorSubject<Metrics>> => {
    const store = state.createStore(
        {
            model: Metrics,
            name: StoreName.METRICS,
            actions: resolveMetricsActions,
            observable: BehaviorSubject,
            links: [
                {
                    name: StoreName.CONFIG,
                    map: populateMetricsLinksFromConfig,
                },
                {
                    name: StoreName.MANIFEST,
                    map: populateMetricsLinksFromManifest,
                },
            ],
        },
        initialValue,
    );

    return store;
};

export {createMetricsStore};
