import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import {Segment} from '@Shared/Models/Segment';
import {createModelOfType} from '@Shared/Util/createModelOfType';

import {MetricsStoreMessageId} from '../Constants/MetricsStoreMessageId';
import {SegmentStatusMachineEventType} from '../Constants/SegmentStatusMachineEventType';
import {segmentStatusMachine} from '../StateMachines/segmentStatusMachine';

interface IAddBufferingRangeParams {
    bufferingSegments: Record<string, Segment>;
    bufferedSegments: Record<string, Segment>;
    segment: Segment;
}

const getBufferingRange = ({bufferingSegments, bufferedSegments, segment}: IAddBufferingRangeParams) => {
    if (bufferingSegments[segment.id]) {
        logger.warnAndDispatchMessage(
            MessageNamespace._026_METRICS_STORE,
            MetricsStoreMessageId._009_SEGMENT_IGNORED_BUFFERING,
            segment.id,
            segment.url,
            segment.periodId,
            segment.language,
            segment.variantIndex,
        );

        return null;
    }

    if (bufferedSegments[segment.id]) {
        logger.warnAndDispatchMessage(
            MessageNamespace._026_METRICS_STORE,
            MetricsStoreMessageId._010_SEGMENT_IGNORED_BUFFERED,
            segment.id,
            segment.url,
            segment.periodId,
            segment.language,
            segment.variantIndex,
        );

        return null;
    }

    // Clone range, so that setting a status is confined
    const bufferingRange = createModelOfType(Segment, segment);

    bufferingRange.status = segmentStatusMachine(
        bufferingRange.status,
        SegmentStatusMachineEventType.SEGMENT_REQUESTED,
    );

    return bufferingRange;
};

export {getBufferingRange};
