import type {IConfigRetry} from '@dazn/hyper';

/**
 * All configuration related to retrying network requests.
 *
 * @title Network Retries
 */

class ConfigRetry {
    public segment: Partial<IConfigRetry> = {
        backoffFactor: 1.5,
        baseDelayMs: 100,
        fuzzFactor: 1,
        maxRetryCount: Infinity,
        retryableStatuses: [404, 408, 410, 425, 429, 500, 502, 503, 504], // adds 404 and 410 to default list
        timeoutCompletionMs: 8000,
        timeoutFirstByteMs: 2000,
    };

    public manifest: Partial<IConfigRetry> = {
        maxRetryCount: 4,
        timeoutFirstByteMs: 5000,
        backoffFactor: 1.5,
        baseDelayMs: 100,
        fuzzFactor: 1,
    };

    /**
     * In the interest of unifying DRM back-off and retry behaviour across HTML5, Android TV and
     * Mobile, we have agreed upon a 2s base delay with 2x exponential back-off w/ jitter.
     *
     * NB: Maximum requests are limited to 1 request + 2 retries to increase the probability of
     * being able to fail gracefully before the 6-8s PlayReady fulfilment deadline (which applies to
     * some devices) kicks-in.
     *
     * @see https://livesport.atlassian.net/wiki/spaces/DE/pages/6165070838/2023-01-19+DRM+Retries+short+term+strategy
     */

    public license: Partial<IConfigRetry> = {
        maxRetryCount: 2,
        timeoutFirstByteMs: 5000,
        backoffFactor: 2,
        baseDelayMs: 2000,
        fuzzFactor: 1.5,
    };
}

export {ConfigRetry};
