import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import {renderSegmentLoggingName} from '@Shared/Util/renderSegmentLoggingName';

import type {TBufferWithholdingActivationOptions} from '../Types/TBufferWithholdingActivationOptions';

import type {AppendQueueItemState} from './AppendQueueItemState';
import {SourceBufferManagerMessageId} from './SourceBufferManagerMessageId';

const getShortBufferWithholdingDescription = (options: TBufferWithholdingActivationOptions) =>
    options.type === 'DURATION' ? `${options.type} (${options.durationSeconds}s)` : options.type;

const sourceBufferManagerMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [SourceBufferManagerMessageId._000_ACTIVATING_BUFFER_WITHHOLDING]: (
            options: TBufferWithholdingActivationOptions,
        ): string =>
            `Activating ${options.type}-based buffer withholding${
                options.type === 'DURATION' ? ` with duration of ${options.durationSeconds}s` : ''
            }`,
        [SourceBufferManagerMessageId._001_ADJUSTING_SEGMENT_MEDIA_TIME]: (mediaTimeOffsetSeconds: number): string =>
            `Adjusting segment media time by ${mediaTimeOffsetSeconds}s`,
        [SourceBufferManagerMessageId._002_APPEND_PENDING_WHILE_AWAITING_ARRIVAL_OF_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Segment append pending while awaiting segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
        [SourceBufferManagerMessageId._003_APPEND_PENDING_WHILE_AWAITING_INIT_SEGMENT_FOR_SEGMENT]: (
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Segment append pending while awaiting init segment for ${renderSegmentLoggingName({
                segmentIndex: -1,
                variantIndex,
                periodId,
                language,
                isInitSegment: true,
            })}`,
        [SourceBufferManagerMessageId._004_APPEND_QUEUE_SYNCED_WITH_QUEUE_OF_LENGTH]: (
            appendQueueLength: number,
        ): string => `Append queue synced and containing ${appendQueueLength} items`,
        [SourceBufferManagerMessageId._005_WITHHOLDING_UNTIL_BUFFER_OF_CURRENT_LENGTH_AT_LEAST_LENGTH]: (
            withholdingDuration: number,
            currentDuration: number,
        ): string =>
            // eslint-disable-next-line max-len
            `Buffer withholding will continue until buffer duration at least ${withholdingDuration}, currently ${currentDuration}`,
        [SourceBufferManagerMessageId._006_BUFFER_APPENDED_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
            wasVirtualAppend: boolean,
        ): string =>
            `Segment appended to ${wasVirtualAppend ? 'virtual' : 'source'} buffer from ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
        [SourceBufferManagerMessageId._007_BUFFERED_RANGE_REMOVED]: (start: number, end: number): string =>
            `Buffered range removed from ${start}s to ${end}s`,
        [SourceBufferManagerMessageId._008_DEACTIVATING_BUFFER_WITHHOLDING]: (): string =>
            'Buffer withholding duration achieved, deactivating buffer withholding',
        [SourceBufferManagerMessageId._009_REMOVE_COMMAND_QUEUED]: (): string => 'Remove command added to remove queue',
        [SourceBufferManagerMessageId._010_END_OF_STREAM]: (): string =>
            'Final segment appended to source buffer, end of stream',
        [SourceBufferManagerMessageId._011_ENQUEUING_APPEND_COMMAND_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Append command added to append queue for segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
        [SourceBufferManagerMessageId._012_ENQUEUING_APPEND_COMMAND_FOR_INIT_SEGMENT]: (
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Append command added to append queue for init segment at ${renderSegmentLoggingName({
                segmentIndex: -1,
                variantIndex,
                periodId,
                language,
                isInitSegment: true,
            })}`,
        [SourceBufferManagerMessageId._013_ENTIRE_BUFFER_REMOVED]: (): string => 'All buffered data removed',
        [SourceBufferManagerMessageId._014_INITIATING_APPEND_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
            requiresInitSegment: boolean,
        ): string =>
            `Initiating append to source buffer for segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}${requiresInitSegment ? ' with prepended init data' : ''}`,
        [SourceBufferManagerMessageId._015_INITIATING_BUFFER_REMOVAL_OF_RANGE]: (start: number, end: number): string =>
            `Initiating buffered range removal from ${start}s to ${end}s`,
        [SourceBufferManagerMessageId._016_INITIATING_ENTIRE_BUFFER_REMOVAL]: (): string =>
            'Initiating removal of all buffered data',
        [SourceBufferManagerMessageId._017_ORPHANED_INIT_SEGMENT_FOUND_IN_APPEND_QUEUE_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Orphaned init segment found in append queue for segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}}`,
        [SourceBufferManagerMessageId._018_ORPHANED_INIT_SEGMENT_ARRIVED_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Orphaned init segment arrived for segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
        [SourceBufferManagerMessageId._021_REPURPOSING_ORPHANED_INIT_SEGMENT_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Repurposing orphaned init segment for segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
        [SourceBufferManagerMessageId._020_RELEASING_QUEUED_APPEND_COMMAND_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Releasing queued append command for segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
        [SourceBufferManagerMessageId._022_REQUEUING_APPEND_WHILE_BUFFER_WITHHOLDING_ACTIVE_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Re-queuing segment append for segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })} while buffer withholding active`,
        [SourceBufferManagerMessageId._023_REQUEUING_APPEND_WHILE_SOURCE_BUFFER_BUSY]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Re-queuing segment append for segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })} while source buffer busy`,
        [SourceBufferManagerMessageId._024_SEGMENT_DROPPED_FROM_APPEND_QUEUE_WHILE_APPENDING_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Redundant segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })} dropped from append queue during append to source buffer, dropping immediately`,
        [SourceBufferManagerMessageId._026_SOURCE_BUFFER_ATTACHED_TO_MEDIA_SOURCE_OF_MIME_TYPE]: (
            mime: string,
        ): string => `Source buffer attached to media source with MIME type ${mime}`,
        [SourceBufferManagerMessageId._027_SOURCE_BUFFER_DETACHED]: (): string => 'Source buffer detached',
        [SourceBufferManagerMessageId._033_UNEXPECTED_SOURCE_BUFFER_UPDATE_IN_PROGRESS]: (): string =>
            'An unknown native process moved the source buffer into an updating state, awaiting idle...',
        [SourceBufferManagerMessageId._035_NATIVE_APPEND_OPERATION_COMPLETE]: (timeToCompleteMs: number): string =>
            `Native source buffer append operation took ${timeToCompleteMs}ms`,
        [SourceBufferManagerMessageId._036_NATIVE_REMOVE_OPERATION_COMPLETE]: (timeToCompleteMs: number): string =>
            `Native source buffer remove operation took ${timeToCompleteMs}ms`,
        [SourceBufferManagerMessageId._037_ANY_UNEXPECTED_SOURCE_BUFFER_UPDATE_IN_PROGRESS]: (): string =>
            'An active source buffer is in an updating state, awaiting idle...',
        [SourceBufferManagerMessageId._039_APPEND_PENDING_WHILE_AWAITING_IDLE_SOURCE_BUFFER]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
            appendQueueItemState: AppendQueueItemState,
        ): string =>
            `Segment append for ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })} pending while awaiting idle Source Buffer (currently ${appendQueueItemState})`,
        [SourceBufferManagerMessageId._040_REMOVE_PENDING_WHILE_AWAITING_IDLE_SOURCE_BUFFER]: (
            removeStartSeconds: number,
            removeEndSeconds: number,
            isRemoving: boolean,
        ): string =>
            `Segment remove for range ${removeStartSeconds}s - ${removeEndSeconds}
             pending while awaiting idle Source Buffer (currently ${isRemoving ? 'removing' : 'appending'})`,
        [SourceBufferManagerMessageId._045_ORPHANED_INIT_SEGMENT_ON_APPEND]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Orphaned init segment stored as segment did not append at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
        [SourceBufferManagerMessageId._046_ORPHANED_INIT_SEGMENT_ON_FLUSH]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Orphaned init segment on append queue flush at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
    },
    [MessageType.WARNING]: {
        [SourceBufferManagerMessageId._019_ORPHANED_SEGMENT_ARRIVED_FOR_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Orphaned segment arrived from downloader at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}`,
        [SourceBufferManagerMessageId._028_SOURCE_BUFFER_FULL]: (): string =>
            'Source buffer memory limit exceeded, source buffer full',
        [SourceBufferManagerMessageId._034_MEDIA_SOURCE_NOT_OPEN]: (readyState: string): string =>
            `Expected a ready state of "open" but got "${readyState}". The media source was closed ` +
            'or detached since the request to attach a source buffer was made. ' +
            'The load request was most likely aborted. Aborting attach request.',
        [SourceBufferManagerMessageId._038_NO_MEDIA_SOURCE]: (): string =>
            `No Media Source found, aborting source buffer operation.`,
        [SourceBufferManagerMessageId._041_NO_SOURCE_BUFFER]: (): string =>
            `No Source Buffer found, aborting source buffer operation.`,
        [SourceBufferManagerMessageId._042_NO_BUFFER_CONTENT_MATCHING_REMOVE_REQUEST]: (
            startSeconds: number,
            endSeconds: number,
        ): string => `No buffered content matching remove range: ${startSeconds}s - ${endSeconds}s`,
        [SourceBufferManagerMessageId._043_REMOVE_REQUEST_INVALID_RANGE]: (
            clampedStartSeconds: number,
            clampedEndSeconds: number,
        ): string => `Remove request does not contain a valid range: ${clampedStartSeconds}}s - ${clampedEndSeconds}s`,
        [SourceBufferManagerMessageId._044_UPDATE_END_NOT_SEEN]: (): string =>
            'Source buffer "updateend" event not seen after 500ms whilst waiting for idle Source Buffer. ' +
            'Checking updating status of Source Buffer',
        [SourceBufferManagerMessageId._047_STALLED_WAITING_FOR_INIT_SEGMENT]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
            appendQueueLength: number,
            segmentSignature: string,
            initSegmentInQueueSignature: string,
            orphanedInitSegmentOnAppendSignature: string,
            orphanedInitSegmentOnEnqueueSignature: string,
            orphanedInitSegmentOnFlushSignature: string,
        ): string =>
            `Stalled waiting for init segment at ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })}\n` +
            `    Append queue length:                        ${appendQueueLength}\n` +
            `    Segment signature:                          ${segmentSignature}\n` +
            `    Init segment in queue signature:            ${initSegmentInQueueSignature}\n` +
            `    Orphaned init segment on append signature:  ${orphanedInitSegmentOnAppendSignature}\n` +
            `    Orphaned init segment on enqueue signature: ${orphanedInitSegmentOnEnqueueSignature}\n` +
            `    Orphaned init segment on flush signature:   ${orphanedInitSegmentOnFlushSignature}`,
        [SourceBufferManagerMessageId._048_REJECTED_BUFFER_WITHHOLDING_ACTIVATION]: (
            [audioOptions, videoOptions]: TBufferWithholdingActivationOptions[],
            requestedOptions: TBufferWithholdingActivationOptions,
        ) =>
            `Rejected buffer withholding activation:\n` +
            `   Current audio options:  ${getShortBufferWithholdingDescription(audioOptions)}\n` +
            `   Current video options:  ${getShortBufferWithholdingDescription(videoOptions)}\n` +
            `   Requested options:      ${getShortBufferWithholdingDescription(requestedOptions)}`,
    },
    [MessageType.ERROR]: {
        [SourceBufferManagerMessageId._025_SOURCE_BUFFER_ALREADY_ATTACHED]: (): string =>
            'Source buffer already attached',
        [SourceBufferManagerMessageId._029_TRANSMUX_ERROR]: (messageOrException: string): string =>
            `Error while transmuxing segment from MP2 to MP4: ${messageOrException}`,
        [SourceBufferManagerMessageId._030_TRANSMUXER_NOT_PRESENT]: (): string =>
            'Transmuxer expected but not present in build',
        [SourceBufferManagerMessageId._031_SOURCE_BUFFER_EXCEPTION]: (messageOrException: string): string =>
            `Unhandled exception thrown from underlying \`SourceBuffer\`: ${messageOrException}`,
        [SourceBufferManagerMessageId._032_MISSING_SEGMENT_DATA]: (
            segmentIndex: number,
            variantIndex: number,
            periodId: string,
            language: string,
        ): string =>
            `Segment ${renderSegmentLoggingName({
                segmentIndex,
                variantIndex,
                periodId,
                language,
            })} is missing buffer data`,
    },
};

export type TSourceBufferManagerMessages = TLoggerMessages<
    MessageNamespace._015_SOURCE_BUFFER_MANAGER_AUDIO | MessageNamespace._016_SOURCE_BUFFER_MANAGER_VIDEO,
    typeof sourceBufferManagerMessages
>;

export {sourceBufferManagerMessages};
