/* eslint-disable max-len */
import {DrmType} from '@DrmController/Constants/DrmType';
import {WidevineRobustness} from '@DrmController/Constants/WidevineRobustness';
import {DrmKeySystemId} from '@Shared/Constants/DrmKeySystemId';

import {ConfigKeySystem} from './ConfigKeySystem';

/**
 * All configuration related to DRM.
 *
 * @configurable
 * @title DRM
 *
 * @remark
 * The DRM configurations define the platform capabilities in relation to EME and available DRM systems.
 */

class ConfigDrm {
    public [DrmKeySystemId.FAIRPLAY] = new ConfigKeySystem();
    public [DrmKeySystemId.PLAYREADY] = new ConfigKeySystem();
    public [DrmKeySystemId.WIDEVINE] = new ConfigKeySystem();
    public [DrmKeySystemId.NONE] = new ConfigKeySystem();

    /**
     * Determines whether or not any active key session should be torn down when exiting
     * an encrypted period (i.e. entering an ad break), such that the correct events are
     * dispatched when re-entering the next encrypted period.
     *
     * @configurable
     * @title revokeKeySessionBetweenEncryptedPeriods (multi-period only)
     *
     * @remark
     * Tizen 2.x is not able to re-enter encrypted periods after ad periods without requiring a new
     * key session - in this scenario it will stall without emitting any further `encrypted` events
     * (or the legacy WebKit equivalent) To mitigate this we set `revokeKeySessionBetweenEncryptedPeriods`,
     * which forces a new key session on entry to encrypted periods, but does result in some user-visible delay
     * - see [Tizen 2.x - Transitioning from Encrypted to Non-Encrypted Segments](./tizen-2x.md#transitioning-from-encrypted-to-non-encrypted-segments).
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_multi_period*.mpd`,
     * CH 94, CH 10 and observing playback.
     */

    public revokeKeySessionBetweenEncryptedPeriods: boolean = false;

    /**
     * The amount of time to wait for an EME key session to close before continuing.
     * Occasionally, session close promises may stall so an escape hatch is needed if
     * awaiting closure before loading a new asset.
     *
     * On 0.1b EME targets, no async `.close()` is provided, so this value is used
     * to define a "cool down" period between closing a key session and tearing down
     * the media source.
     */

    public keySessionCloseTimeoutMs = 500;

    /**
     * Determines a specific type of DRM to use when decrypting encrypted assets. By default, the player will attempt to
     * use the first supported system available in the browser AND manifest, but it may be necessary to restrict this on
     * some targets.
     *
     * When the first manifest of a live stream only contains unencrypted periods, if `DrmType.ANY` is selected, the
     * player will default to the first supported DRM type supplied by the browser. This is to avoid a media pipeline
     * error on the transition from unencrypted to encrypted content.
     *
     * @configurable
     *
     * @remark
     * Tizen 2.x claims to support both Widevine and PlayReady, but any session requests with a Widevine key system will
     * fail - see [Tizen 2.x - Available CDMs](./tizen-2x.md#available-cdms). This is also the case for some LG WebOS
     * devices although there is no evidence that is a widespread issue in Production.
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_cenc.mpd`, using all reportedly supported
     * CDMs, and observing playback.
     */

    public drmType: DrmType = DrmType.ANY;

    /**
     * Determines if PSSH data from the manifest should be used to trigger the creation of a key
     * session.
     *
     * @configurable
     *
     * @remark
     * For most platforms, init segments and manifest are both acceptable for PSSH data used to trigger
     * the creation of a key session. On Tizen 2.x, key sessions seeded from manifest content protection
     * data will always fail, so it is disabled for cleaner logging
     * - see [Tizen 2.x - Usage of PSSH data from manifest vs encrypted init segments](./tizen-2x.md#usage-of-pssh-data-from-manifest-vs-encrypted-init-segments).
     * We mitigate this by disabling `canUseManifestAsSource` and only enabling `canUseInitSegmentAsSource`.
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_cenc.mpd` and observing playback.
     */

    public canUseManifestAsSource: boolean = true;

    /**
     * Determines if PSSH data from encrypted init segments should be used to trigger the creation
     * of a key session.
     *
     * @configurable
     *
     * @remark
     * For most platforms, init segments and manifest are both acceptable for PSSH data used to trigger
     * the creation of a key session. On Movistar, because <LA_URL> synthesis is required, we are currently
     * limited to the manifest DRM path only, because we can parse it as text and manipulate it more easily
     * - see [Movistar - Requirement for `<LA_URL>` Element in PSSH Data](./movistar.md#requirement-for-la_url-element-in-pssh-data).
     * We mitigate this by disabling `canUseInitSegmentAsSource` and only enabling `canUseManifestAsSource`.
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_cenc.mpd` and observing playback.
     */

    public canUseInitSegmentAsSource: boolean = true;

    /**
     * Prevents the invocation of a tear down (e.g. in response to `.load()`, `.unload()`, `.destroy()`,
     * or an error), while a DRM key session is pending. On some targets (notably Tizen 2.x) the application
     * _may_ crash if the player internals are torn down at the wrong moment in the key session lifecycle.
     *
     * If enabled, the player will wait until the key session is successfully fulfilled (or a timeout duration
     * occurs) before then initiating tear down.
     *
     * @configurable
     *
     * @remark
     * On Tizen 3+ when loading a DAI stream, if a key session was pending during tear down of the Regional manifest,
     * the MediaSource will become unresponsive to buffered content and will never fire the "loadedmetadata" event.
     * This results in the content being unplayable until a CDN rotation occurs. This may no longer be necessary after
     * the introduction of manifest side-loading, as no regional manifest is ever loaded. Tizen 2.x is prone to fatal
     * application crashes if the media pipeline is torn down while a DRM key session is pending -
     * see [Tizen 2.x - EME Key Session Termination](./tizen-2x.md#eme-key-session-termination)
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_multi_period*.mpd`,
     * CH 94, CH 10 and observing playback. In particular, unload the asset whilst the key session is pending.
     */

    public preventTearDownDuringPendingKeySession: boolean = false;

    /**
     * Determines if a "dummy" `<LA_URL>` XML element should be inserted into Playready PSSH data parsed
     * from the manifest. The Playready CDMs on certain platforms (Movistar, Magenta) require this,
     * even though it is not consumed or used.
     *
     * @configurable
     *
     * @remark
     * Relevant for targets using Playready CDM where decrypting encrypted assets fails
     * - see [Movistar - Requirement for <LA_URL> Element in PSSH Data](./movistar.md#requirement-for-la_url-element-in-pssh-data).
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_cenc.mpd`,
     * using Playready CDMs, and observing playback.
     */

    public insertPlayreadyPsshLaUrl: boolean = false;

    /**
     * Determines video robustness level to use for Widevine DRM.
     *
     * @configurable
     *
     * @remark
     * Widevine CDM can be configured per the following configurations, in security + speed order:
     * [HW_SECURE_ALL, HW_SECURE_DECODE, HW_SECURE_CRYPTO, SW_SECURE_DECODE, SW_SECURE_CRYPTO, EMPTY].
     * This is relevant for targets using Widevine CDM where decrypting encrypted assets fails.
     * Try each cascading from HW_SECURE_ALL to EMPTY until you land on one that works. Going straight
     * for EMPTY will likely work, but is unlikely to yield the best results, with Panasonic being one
     * potential example of this at the time of writing.
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_multi_period*.mpd`,
     * CH 94, CH 10, using Playready CDMs, and observing playback. Pay particular interest to startup time
     * and time in DRM pending state.
     */

    public widevineVideoRobustness = WidevineRobustness.SW_SECURE_CRYPTO;

    /**
     * Determines if concurrent DRM key sessions are permitted / needed. Can be used to prevent
     * unnecessary additional key session requests, which can result in media errors on some platforms.
     *
     * @configurable
     *
     * @remark
     * Tizen 2.x requires separate key sessions for video and audio whereas on newer Tizen versions we must
     * avoid a situation where separate key sessions are created for manifest and init segment seed data when
     * initData can not be matched, as this leads to media errors. This issue has been noticed at scale in PROD
     * where a 11009 error is thrown after appearing to create two key sessions simultaneously (both which succeed).
     *
     * @test
     * It may difficult to recreate manually under normal conditions, although it should be possible to force it
     * by having differing init data between the init segments and manifest.
     */

    public allowConcurrentKeySessions: boolean = false;

    /**
     * Determines if the license for a successfully authenticated key session may be cached on the
     * CDM and re-used for subsequent key sessions with the same key ID.
     *
     * @configurable
     *
     * @remark
     * Mercury has the concept of re-using previously successful key sessions for future media
     * encrypted with the same key (which applies to DVR window "flipping", CDN rotation, and
     * switching between multiple assets using the same key). This only works if the device allows
     * us to re-use keys, which isn't usually the case, and is disabled at the config level for all
     * devices.
     */

    public usePersistentLicenses: boolean = false;

    /**
     * Determines if a device may directly re-use a cached PlayReady license response from a
     * previous request for subsequent key sessions with the same key ID, as a means of "faking"
     * license persistent where it is not natively supported. May only be used if
     * `usePersistentLicenses` is enabled.
     *
     * @configurable
     *
     * @remark
     * Although this should not be possible due to the fact that each license should be uniquely
     * seeded from a unique challenge, it appears that on some legacy PlayReady CDMs persistent
     * licenses may be directly re-used across assets with the same key IDs. Using this approach can
     * lead to a silent failure (and prolonged buffering) where the key session is updated but
     * playback is not achieved. Therefore care and extensive manual testing should be undertaken
     * before deciding whether to use this strategy.
     */

    public canReuseCachedLicenses: boolean = false;

    /**
     * Determines the minimum acceptable time remaining on any persistent license, in order to
     * re-use it. Cached licenses with a time remaining less than this value will be discarded to
     * prevent any risk of expiration during content, and a new license shall be requested instead.
     *
     * @configurable
     *
     * @remark
     * This value is currently informed by the maximum duration of any DAZN live event (cricket and
     * tennis being the longest). In the future, a dynamic key re-issuance flow may be implemented
     * so that such logic may no longer be necessary.
     */

    public minimumPersistentLicenseTimeToExpireMs: number = 12 * 60 * 60 * 1000; // 12 hrs

    /**
     * Determines the default fallback duration for any persistent key session where the actual
     * license expiration is unknown because the CDM has not provided it (typically only `NaN`
     * is provided).
     *
     * @configurable
     *
     * @remark
     * This value must be updated in line with any changes to our license policy at the license
     * server level.
     */

    public defaultPersistentLicenseDurationMs: number = 24 * 60 * 60 * 1000; // 24 hrs

    /**
     * Determines whether the native `MediaKeySessions` instances created for successful playback
     * sessions, should be persisted in memory (and remain attached to the video element) across
     * multiple media sources.
     *
     * @configurable
     *
     * @remark
     * Persisting key sessions ensures that if a subsequent media source encrypted with the same
     * "key ID" as an active key session is detected, playback will start immediately _without_ the
     * need for a license request, or retrieval of a persistent license.
     */

    public persistNativeKeySessions: boolean = true;

    /**
     * Determines whether to throw an error during CDM closing if MediaKeys are not null.
     *
     * @configurable
     *
     * @remark
     * If set to false allows to skip the error thrown when the MediaKeys are not null during CDM closing.
     * Part of CDM_UNAVAILABLE issue workaround on Sky Q devices.
     * See https://livesport.atlassian.net/wiki/x/xQFJkwE
     */

    public throwErrorOnMediaKeysPresenceDuringCdmClosing: boolean = true;

    /**
     * The maximum number of key sessions (metadata, and optionally, the underlying native
     * `MediaKeySession` to persist in memory across media sources.
     *
     * @configurable
     * Enforcing a limit avoids memory leaks where a player is kept alive for a considerable time
     * and used to play many different assets with different key IDs.
     *
     * @remark
     * When the max is exceeded at the point of unload, key sessions will be closed and garbage
     * collected oldest first, until the we are within bounds again.
     */

    public maxKeySessions: number = 20;

    /**
     * This flag decides whether the standard codec check via MediaSource.isTypeSupported is needed
     * By default, set to true, so all platforms go via standard codec check
     * But on Tizen-legacy since it has a fake implementation of `isTypeSupported`
     * which returns true for any input. This flag will be set to false via the profile
     *
     */
    public canUseStandardCodecSupported: boolean = true;

    /**
     * This flag is used to enable simple regex based codec matching (as used in Tizen-legacy) as
     * a fallback when MediaSource.isTypeSupported check fails. This will be enabled via
     * Feature Visor flag such that it can be enabled as per need.
     */
    public canUseRegexForCodecSupported: boolean = false;

    public get overriddenDrmKeySystemId(): null | DrmKeySystemId {
        return {
            [DrmType.PLAYREADY]: DrmKeySystemId.PLAYREADY,
            [DrmType.WIDEVINE]: DrmKeySystemId.WIDEVINE,
            [DrmType.FAIRPLAY]: DrmKeySystemId.FAIRPLAY,
            [DrmType.ANY]: null,
        }[this.drmType];
    }
}

export {ConfigDrm};
