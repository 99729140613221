import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import type {ILogger} from '@Logger/Interfaces/ILogger';
import {logger} from '@Logger/logger';
import {objectAssign} from '@Shared/Util/objectAssign';
import type {LocalStorage} from '@State/Stores/LocalStorage/Models/LocalStorage';

import {FeatureLocalStorageMessageId} from '../Constants/FeatureLocalStorageMessageId';
import {MERCURY} from '../Constants/localStorageKeys';

interface IDependencies {
    localStorage: Storage;
    logger: ILogger;
}

const readFromLocalStorage = (
    dependencies: IDependencies = {localStorage: window.localStorage, logger},
): Partial<LocalStorage> => {
    const value: Partial<LocalStorage> = {};

    try {
        const json = dependencies.localStorage.getItem(MERCURY) ?? '{}';

        objectAssign(value, JSON.parse(json));
    } catch (err) {
        dependencies.logger.warn(
            MessageNamespace._029_FEATURE_LOCAL_STORAGE,
            FeatureLocalStorageMessageId._000_READ_FAILURE,
            MERCURY,
        );
    }

    return value;
};

export {readFromLocalStorage};
