import type {TEnumerationValue} from '@Shared/Util/enumeration';
import {enumeration} from '@Shared/Util/enumeration';

const AdaptivePresentationDelayStatus = enumeration([
    // Adaptive Presentation Delay feature is not enabled
    'DISABLED',
    // Initial state for Adaptive Presentation Delay feature when it's enabled but no action has happened yet
    // It's intentionally separated from SYNCHRONISED status because no playback rate has to be set at this stage
    'IDLE',
    // Current distance to end of availability window is greater than presentation delay
    // So pulling the user closer to the presentation delay
    // The playback rate is set to `UNNOTICEABLY_INCREASED_PLAYBACK_RATE`
    'DECREASING',
    // The user currently sits at the presentation delay, including `PRESENTATION_DELAY_MARGIN_SECONDS`
    // The playback rate is set back to `Playback.DEFAULT_PLAYBACK_RATE`
    'SYNCHRONISED',
    // Current distance to the end of availability window is less than presentation delay
    // So returning user back to the presentation delay
    // The playback rate is set to `UNNOTICEABLY_DECREASED_PLAYBACK_RATE`
    'INCREASING',
]);

type TAdaptivePresentationDelayStatus = TEnumerationValue<typeof AdaptivePresentationDelayStatus>;

export type {TAdaptivePresentationDelayStatus};
export {AdaptivePresentationDelayStatus};
