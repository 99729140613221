import type {TAdaptivePresentationDelayStatus} from '@AdaptivePresentationDelayManager/Const/AdaptivePresentationDelayStatus';
import type {TKeySessionType} from '@DrmController/Types/TKeySessionType';
import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import type {MediaElementReadyState} from '@Shared/Constants/MediaElementReadyState';

import type {KeySessionStatusMachineEventType} from '../Constants/KeySessionStatusMachineEventType';
import type {LifecycleStatusMachineEventType} from '../Constants/LifecycleStatusMachineEventType';
import {MetricsStoreMessageId} from '../Constants/MetricsStoreMessageId';
import {PlaybackStatus} from '../Constants/PlaybackStatus';
import type {PlaybackStatusMachineEventType} from '../Constants/PlaybackStatusMachineEventType';
import type {Metrics} from '../Models/Metrics';
import {keySessionStatusMachine} from '../StateMachines/keySessionStatusMachine';
import {lifecycleStatusMachine} from '../StateMachines/lifecycleStatusMachine';
import {playbackStatusMachine} from '../StateMachines/playbackStatusMachine';
import {stalledStatusMachine} from '../StateMachines/stalledStatusMachine';
import {deriveEventTypeFromStalledStatus} from '../Util/deriveEventTypeFromStalledStatus';

type TPlaybackActions = {
    endSeek(): void;
    setCdmOpen(isCdmOpen: boolean): void;
    setHasDecodedFrames(): void;
    setIsJumpingSourceBufferGap(): void;
    setIsReplacingMediaPipeline(): void;
    setKeySessionStatus(eventType: KeySessionStatusMachineEventType): void;
    setKeySessionType(eventType: TKeySessionType): void;
    setLifecycleStatus(eventType: LifecycleStatusMachineEventType): void;
    setMediaElementReadyState(mediaElementReadyState: MediaElementReadyState): void;
    setPlaybackQuality(droppedFrames: number, totalFrames: number): void;
    setPlaybackRate(playbackRate: number): void;
    setPlaybackStatus(eventType: PlaybackStatusMachineEventType): void;
    setAdaptivePresentationDelayStatus(status: TAdaptivePresentationDelayStatus): void;
    setStalledStatus(stalled: boolean): PlaybackStatusMachineEventType;
    startSeek(): void;
    unsetHasDecodedFrames(): void;
    unsetIsJumpingSourceBufferGap(): void;
    unsetIsReplacingMediaPipeline(): void;
};

const resolvePlaybackActions = ({playback, timing}: Metrics): TPlaybackActions => ({
    setMediaElementReadyState(mediaElementReadyState) {
        playback.mediaElementReadyState = mediaElementReadyState;
    },

    setHasDecodedFrames() {
        playback.hasDecodedFrames = true;
    },

    unsetHasDecodedFrames() {
        playback.hasDecodedFrames = false;
    },

    setPlaybackStatus(eventType) {
        const nextPlaybackStatus = playbackStatusMachine(playback.playbackStatus, eventType);

        if (nextPlaybackStatus !== playback.playbackStatus) {
            logger.log(
                MessageNamespace._026_METRICS_STORE,
                MetricsStoreMessageId._000_PLAYBACK_STATUS_CHANGE,
                playback.playbackStatus,
                nextPlaybackStatus,
                eventType,
            );
        }

        if (nextPlaybackStatus === PlaybackStatus.PLAYING && !playback.hasPlayed) {
            playback.hasPlayed = true;
        }

        playback.playbackStatus = nextPlaybackStatus;
    },

    setLifecycleStatus(eventType) {
        const nextLifecycleStatus = lifecycleStatusMachine(playback.lifecycleStatus, eventType);

        if (nextLifecycleStatus !== playback.lifecycleStatus) {
            logger.log(
                MessageNamespace._026_METRICS_STORE,
                MetricsStoreMessageId._003_LIFECYCLE_STATUS_CHANGE,
                playback.lifecycleStatus,
                nextLifecycleStatus,
                eventType,
            );
        }

        playback.lifecycleStatus = nextLifecycleStatus;
    },

    startSeek() {
        playback.isSeeking = true;
    },

    endSeek() {
        if (playback.isSeeking) playback.isSeeking = false;
    },

    setStalledStatus(stalled) {
        const nextStalledStatus = stalledStatusMachine(playback.stalledStatus, playback, timing, !stalled);

        if (nextStalledStatus !== playback.stalledStatus && stalled) {
            logger.log(
                MessageNamespace._026_METRICS_STORE,
                MetricsStoreMessageId._004_STALLED,
                playback.stalledStatus,
                nextStalledStatus,
            );
        }

        playback.stalledStatus = nextStalledStatus;

        return deriveEventTypeFromStalledStatus(nextStalledStatus);
    },

    setCdmOpen(isCdmOpen) {
        playback.isCdmOpen = isCdmOpen;
    },

    setKeySessionStatus(eventType) {
        const nextKeySessionStatus = keySessionStatusMachine(playback.keySession.status, eventType);

        if (nextKeySessionStatus !== playback.keySession.status) {
            logger.log(
                MessageNamespace._026_METRICS_STORE,
                MetricsStoreMessageId._005_KEY_SESSION_STATUS_CHANGE,
                playback.keySession.status,
                nextKeySessionStatus,
                eventType,
            );
        }

        playback.keySession.status = nextKeySessionStatus;
    },

    setKeySessionType(eventType) {
        playback.keySession.type = eventType;
    },

    setPlaybackRate(playbackRate) {
        playback.playbackRate = playbackRate;
    },

    setAdaptivePresentationDelayStatus(status) {
        playback.adaptivePresentationDelayStatus = status;
    },

    setPlaybackQuality(droppedVideoFrames, totalVideoFrames) {
        const previous = playback.quality;
        const now = Date.now();
        const droppedVideoFramesDiff = droppedVideoFrames - previous.droppedVideoFrames;
        const timeDiffSeconds = (now - previous.lastUpdateWallclockTimeMs) / 1000;
        const droppedVideoFramesSpeed = droppedVideoFramesDiff / timeDiffSeconds;
        const droppedVideoFramesAcceleration =
            (droppedVideoFramesSpeed - previous.droppedVideoFramesSpeed) / timeDiffSeconds;

        playback.quality.droppedVideoFramesSpeed = droppedVideoFramesSpeed;
        playback.quality.droppedVideoFramesAcceleration = droppedVideoFramesAcceleration;
        playback.quality.droppedVideoFrames = droppedVideoFrames;
        playback.quality.lastUpdateWallclockTimeMs = now;
        playback.quality.totalVideoFrames = totalVideoFrames;
        playback.quality.videoFrameFailureRate = droppedVideoFrames / totalVideoFrames;
    },

    setIsReplacingMediaPipeline() {
        playback.isReplacingMediaPipeline = true;
    },

    unsetIsReplacingMediaPipeline() {
        playback.isReplacingMediaPipeline = false;
    },

    setIsJumpingSourceBufferGap() {
        playback.isJumpingSourceBufferGap = true;
    },

    unsetIsJumpingSourceBufferGap() {
        playback.isJumpingSourceBufferGap = false;
    },
});

export type {TPlaybackActions};
export {resolvePlaybackActions};
