import {PlaybackStatus} from '../Constants/PlaybackStatus';
import {PlaybackStatusMachineEventType} from '../Constants/PlaybackStatusMachineEventType';

const playbackStatusMachine = (
    prevPlaybackStatus: PlaybackStatus,
    eventType: PlaybackStatusMachineEventType,
): PlaybackStatus => {
    switch (prevPlaybackStatus) {
        case PlaybackStatus.BUFFERING:
            switch (eventType) {
                case PlaybackStatusMachineEventType.PROGRESS_WITHIN_BUFFERED_RANGE:
                    return PlaybackStatus.PLAYING;
                case PlaybackStatusMachineEventType.DESTROY:
                    return PlaybackStatus.DESTROYED;
                case PlaybackStatusMachineEventType.ERROR:
                    return PlaybackStatus.ERRORED;
                case PlaybackStatusMachineEventType.UNLOAD:
                    return PlaybackStatus.UNLOADED;
                case PlaybackStatusMachineEventType.END:
                    return PlaybackStatus.ENDED;
                case PlaybackStatusMachineEventType.PAUSE:
                    return PlaybackStatus.PAUSED;
                default:
                    return prevPlaybackStatus;
            }

        case PlaybackStatus.ENDED:
            switch (eventType) {
                case PlaybackStatusMachineEventType.SEEK_WITHIN_BUFFERED_RANGE:
                    return PlaybackStatus.PLAYING;
                case PlaybackStatusMachineEventType.SEEK_OUTSIDE_BUFFERED_RANGE:
                    return PlaybackStatus.BUFFERING;
                case PlaybackStatusMachineEventType.DESTROY:
                    return PlaybackStatus.DESTROYED;
                case PlaybackStatusMachineEventType.ERROR:
                    return PlaybackStatus.ERRORED;
                case PlaybackStatusMachineEventType.UNLOAD:
                    return PlaybackStatus.UNLOADED;
                default:
                    return prevPlaybackStatus;
            }

        case PlaybackStatus.LOADED:
            switch (eventType) {
                case PlaybackStatusMachineEventType.PROGRESS_OUTSIDE_BUFFERED_RANGE:
                    return PlaybackStatus.BUFFERING;
                case PlaybackStatusMachineEventType.PAUSE:
                    return PlaybackStatus.PAUSED;
                case PlaybackStatusMachineEventType.DESTROY:
                    return PlaybackStatus.DESTROYED;
                case PlaybackStatusMachineEventType.ERROR:
                    return PlaybackStatus.ERRORED;
                case PlaybackStatusMachineEventType.UNLOAD:
                    return PlaybackStatus.UNLOADED;
                default:
                    return prevPlaybackStatus;
            }

        case PlaybackStatus.LOADING:
            switch (eventType) {
                case PlaybackStatusMachineEventType.MANIFEST_PARSED:
                    return PlaybackStatus.LOADED;
                case PlaybackStatusMachineEventType.DESTROY:
                    return PlaybackStatus.DESTROYED;
                case PlaybackStatusMachineEventType.ERROR:
                    return PlaybackStatus.ERRORED;
                case PlaybackStatusMachineEventType.UNLOAD:
                    return PlaybackStatus.UNLOADED;
                default:
                    return prevPlaybackStatus;
            }

        case PlaybackStatus.PAUSED:
            switch (eventType) {
                case PlaybackStatusMachineEventType.RESUME:
                    return PlaybackStatus.PLAYING;
                case PlaybackStatusMachineEventType.DESTROY:
                    return PlaybackStatus.DESTROYED;
                case PlaybackStatusMachineEventType.ERROR:
                    return PlaybackStatus.ERRORED;
                case PlaybackStatusMachineEventType.UNLOAD:
                    return PlaybackStatus.UNLOADED;
                default:
                    return prevPlaybackStatus;
            }

        case PlaybackStatus.PLAYING:
            switch (eventType) {
                case PlaybackStatusMachineEventType.STALL_AWAITING_KEY_SESSION:
                case PlaybackStatusMachineEventType.STALL_CAUSE_UNKNOWN:
                    return PlaybackStatus.BUFFERING;
                case PlaybackStatusMachineEventType.PAUSE:
                    return PlaybackStatus.PAUSED;
                case PlaybackStatusMachineEventType.PROGRESS_OUTSIDE_BUFFERED_RANGE:
                case PlaybackStatusMachineEventType.SEEK_OUTSIDE_BUFFERED_RANGE:
                    return PlaybackStatus.BUFFERING;
                case PlaybackStatusMachineEventType.END:
                    return PlaybackStatus.ENDED;
                case PlaybackStatusMachineEventType.DESTROY:
                    return PlaybackStatus.DESTROYED;
                case PlaybackStatusMachineEventType.ERROR:
                    return PlaybackStatus.ERRORED;
                case PlaybackStatusMachineEventType.UNLOAD:
                    return PlaybackStatus.UNLOADED;
                default:
                    return prevPlaybackStatus;
            }

        case PlaybackStatus.UNLOADED:
            switch (eventType) {
                case PlaybackStatusMachineEventType.MANIFEST_REQUEST:
                    return PlaybackStatus.LOADING;
                case PlaybackStatusMachineEventType.DESTROY:
                    return PlaybackStatus.DESTROYED;
                case PlaybackStatusMachineEventType.ERROR:
                    return PlaybackStatus.ERRORED;
                default:
                    return prevPlaybackStatus;
            }

        case PlaybackStatus.ERRORED:
            switch (eventType) {
                case PlaybackStatusMachineEventType.MANIFEST_REQUEST:
                    return PlaybackStatus.LOADING;
                case PlaybackStatusMachineEventType.DESTROY:
                    return PlaybackStatus.DESTROYED;
                case PlaybackStatusMachineEventType.UNLOAD:
                    return PlaybackStatus.UNLOADED;
                default:
                    return prevPlaybackStatus;
            }

        case PlaybackStatus.DESTROYED:
        default:
            return prevPlaybackStatus;
    }
};

export {playbackStatusMachine};
