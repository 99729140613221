import {CodecGenericName} from '@Shared/Constants/CodecGenericName';
import type {ICodecNames} from '@Shared/Interfaces/ICodecNames';
import type {Variant} from '@Shared/Models/Variant';

const getCodecNamesFromVariants = (variants: Variant[]): ICodecNames => {
    const codecNames = {
        audioCodecName: '',
        videoCodecName: '',
    };

    // Generally our manifests carry variants with codecs which belong to the
    // same codec category but with different profile information based on the bitrate
    // Some examples:
    //    AVC - avc1.4D401F, avc1.640020, avc1.64002A
    //    HEVC - hev1.1.60000000.L93.00.B0, hev1.1.60000000.L120.00.B0, hev1.1.60000000.L123.00.B0
    // Hence in order to identify the manifest level codec names, it is enough to pick any variant from the list
    // So for this derivation, we would pick the first variant from the list.
    const firstVariant = variants[0];

    if (firstVariant) {
        const audioCodecWithoutProfile = firstVariant.audioCodec.split('.')[0];
        const videoCodecWithoutProfile = firstVariant.videoCodec.split('.')[0];

        codecNames.audioCodecName = CodecGenericName[audioCodecWithoutProfile];
        codecNames.videoCodecName = CodecGenericName[videoCodecWithoutProfile];
    }

    return codecNames;
};

export {getCodecNamesFromVariants};
