import {arrayIncludes} from '@dazn/peng-html5-tools-utils';

import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import {StreamType} from '@Shared/Constants/StreamType';
import type {Segment} from '@Shared/Models/Segment';
import {createEwmaComputation} from '@Shared/Util/createEwmaComputation';

import {MetricsStoreMessageId} from '../Constants/MetricsStoreMessageId';
import {PlayheadStatus} from '../Constants/PlayheadStatus';
import {PlayheadStatusMachineEventType} from '../Constants/PlayheadStatusMachineEventType';
import {SegmentStatusMachineEventType} from '../Constants/SegmentStatusMachineEventType';
import type {Metrics} from '../Models/Metrics';
import {Timing} from '../Models/Timing';
import {bufferHealthStatusMachine} from '../StateMachines/bufferHealthStatusMachine';
import {playheadStatusMachine} from '../StateMachines/playheadStatusMachine';
import {segmentStatusMachine} from '../StateMachines/segmentStatusMachine';
import {addBufferedRange} from '../Util/addBufferedRange';
import {dropAllBufferingRanges} from '../Util/dropAllBufferingRanges';
import {dropBufferedRange} from '../Util/dropBufferedRange';
import {getAllRanges} from '../Util/getAllRanges';
import {getBufferingRange} from '../Util/getBufferingRange';
import type {IGetPlayheadStatusAndRangeIndexOptions} from '../Util/getPlayheadStatusAndRangeIndex';
import {getPlayheadStatusAndRangeIndex} from '../Util/getPlayheadStatusAndRangeIndex';
import {hasBufferHealthStatusDeteriorated} from '../Util/hasBufferHealthStatusDeteriorated';

type TTimingActions = {
    abortPendingSegments(): void;
    addAudioBufferedSegment(segment: Segment): void;
    addAudioBufferingSegment(segment: Segment): void;
    addVideoBufferedSegment(segment: Segment): void;
    addVideoBufferingSegment(segment: Segment): void;
    dropAudioBufferedRange(start?: number, end?: number): void;
    dropVideoBufferedRange(start?: number, end?: number): void;
    emptyAudioBuffer(): void;
    emptyBuffers(): void;
    emptyVideoBuffer(): void;
    markAppendingAudioSegment(segment: Segment): void;
    markAppendingVideoSegment(segment: Segment): void;
    markDownloadedAudioSegment(segment: Segment): void;
    markDownloadedVideoSegment(segment: Segment): void;
    setAvailabilityTimeOffsetSeconds(availabilityTimeOffsetSeconds: number): void;
    setBufferHealthStatus(): boolean;
    setCurrentTime(nextCurrentTime: number, updateFrequency?: boolean): void;
    setDesiredPresentationDelay(desiredPresentationDelaySeconds: number): void;
    setDeviceClockOffset(deviceClockOffsetMs: number): void;
    setEffectiveHungryBufferAheadDuration(): void;
    setJoinTime(joinTimeSeconds: number): void;
    setPlayheadStatus(): boolean;
};

const calculateNextTimeFrequencyValue = createEwmaComputation();

const resolveTimingActions = ({timing, playback}: Metrics): TTimingActions => ({
    setCurrentTime(nextCurrentTime, updateFrequency = false) {
        if (updateFrequency && timing.currentTimeSeconds !== Timing.DEFAULT_CURRENT_TIME_SECONDS) {
            timing.timeUpdateFrequencySeconds = calculateNextTimeFrequencyValue(
                nextCurrentTime - timing.currentTimeSeconds,
                timing.timeUpdateFrequencySeconds,
            );
        }

        timing.currentTimeSeconds = nextCurrentTime;
    },

    // in-flight requests + segments awaiting append
    abortPendingSegments() {
        dropAllBufferingRanges(timing.audioBufferData);
        dropAllBufferingRanges(timing.videoBufferData);
    },

    emptyAudioBuffer() {
        dropAllBufferingRanges(timing.audioBufferData, true);

        timing.audioBufferData.bufferedRangeMap = {};
    },

    emptyBuffers() {
        this.emptyAudioBuffer();
        this.emptyVideoBuffer();
    },

    emptyVideoBuffer() {
        dropAllBufferingRanges(timing.videoBufferData, true);

        timing.videoBufferData.bufferedRangeMap = {};
    },

    setEffectiveHungryBufferAheadDuration() {
        timing.effectiveHungryBufferAheadDurationSeconds = timing.combinedBufferAheadDurationSeconds;
    },

    setBufferHealthStatus() {
        const {isSeeking, isBuffering} = playback;
        const {timeRemainingSeconds, bufferHealthStatus: prevBufferHealthStatus, bufferAheadGoalSeconds} = timing;

        const isApproachingEndOfStream = !timing.isDynamic && timeRemainingSeconds <= bufferAheadGoalSeconds;

        if (isSeeking || isBuffering || isApproachingEndOfStream) return false;

        const nextBufferHealthStatus = bufferHealthStatusMachine(
            timing.bufferHealthStatus,
            timing.combinedBufferAheadDurationSeconds,
            timing.configAbr,
            timing.bufferAheadGoalSeconds,
        );

        const hasDeteriorated = hasBufferHealthStatusDeteriorated(prevBufferHealthStatus, nextBufferHealthStatus);

        if (nextBufferHealthStatus !== timing.bufferHealthStatus) {
            logger.log(
                MessageNamespace._026_METRICS_STORE,
                MetricsStoreMessageId._002_BUFFER_HEALTH_STATUS_CHANGE,
                timing.bufferHealthStatus,
                nextBufferHealthStatus,
            );
        }

        timing.bufferHealthStatus = nextBufferHealthStatus;

        return hasDeteriorated;
    },

    setPlayheadStatus() {
        const {configAbr, currentTimeSeconds, maxGapOffsetSeconds} = timing;
        const {smallGapThresholdSeconds, stallThresholdSeconds} = configAbr;
        const allAudioRanges = getAllRanges(timing.audioBufferData.bufferedRangeMap);
        const allVideoRanges = getAllRanges(timing.videoBufferData.bufferedRangeMap);

        const sharedArgs: Omit<IGetPlayheadStatusAndRangeIndexOptions, 'ranges'> = {
            currentTimeSeconds,
            smallGapThresholdSeconds,
            stallThresholdSeconds,
            safeGapOffsetSeconds: maxGapOffsetSeconds,
        };

        const [audioPlayheadStatus] = getPlayheadStatusAndRangeIndex({
            ranges: allAudioRanges,
            ...sharedArgs,
        });
        const [videoPlayheadStatus] = getPlayheadStatusAndRangeIndex({
            ranges: allVideoRanges,
            ...sharedArgs,
        });

        let nextPlayheadStatus: PlayheadStatus;

        if (arrayIncludes([audioPlayheadStatus, videoPlayheadStatus], PlayheadStatus.OUTSIDE_BUFFER)) {
            // If the playhead is outside of either the audio OR video, treat as EXIT_BUFFER

            nextPlayheadStatus = playheadStatusMachine(
                timing.playheadStatus,
                // NB: Sometimes `currentTime` can surpass the `seekRangeEndTime`, leading the
                // player to believe it is outside of the buffered range, when it has simply ended.
                // Ignore this phenomenon if we are at the end at the stream.
                timing.isAtOrBeyondEndOfStream
                    ? PlayheadStatusMachineEventType.ENTER_BUFFER
                    : PlayheadStatusMachineEventType.EXIT_BUFFER,
            );
        } else if (arrayIncludes([audioPlayheadStatus, videoPlayheadStatus], PlayheadStatus.INSIDE_GAP)) {
            // If the playhead is in either a audio OR video gap, treat as ENTER_GAP

            nextPlayheadStatus = playheadStatusMachine(timing.playheadStatus, PlayheadStatusMachineEventType.ENTER_GAP);
        } else {
            nextPlayheadStatus = playheadStatusMachine(
                timing.playheadStatus,
                PlayheadStatusMachineEventType.ENTER_BUFFER,
            );
        }

        if (nextPlayheadStatus !== timing.playheadStatus) {
            logger.log(
                MessageNamespace._026_METRICS_STORE,
                MetricsStoreMessageId._001_PLAYHEAD_STATUS_CHANGE,
                timing.playheadStatus,
                nextPlayheadStatus,
            );
        }

        timing.playheadStatus = nextPlayheadStatus;

        return timing.isInsideBufferOrGap;
    },

    // Video Buffer Actions

    addVideoBufferingSegment(segment) {
        const bufferingRange = getBufferingRange({
            bufferingSegments: timing[StreamType.VIDEO].bufferingRangeMap,
            bufferedSegments: timing[StreamType.VIDEO].bufferedRangeMap,
            segment,
        });

        if (!bufferingRange) return;

        timing[StreamType.VIDEO].bufferingRangeMap[bufferingRange.id] = bufferingRange;
        timing[StreamType.VIDEO].previousBufferingSegment = bufferingRange;
    },

    addVideoBufferedSegment(segment) {
        addBufferedRange(timing, segment, StreamType.VIDEO);
    },

    dropVideoBufferedRange(start = 0, end = Infinity) {
        dropBufferedRange(timing.videoBufferData, start, end);
    },

    markAppendingVideoSegment(segment) {
        const bufferingSegment = timing.videoBufferData.bufferingRangeMap[segment.id];

        if (!bufferingSegment) return;

        bufferingSegment.status = segmentStatusMachine(
            bufferingSegment.status,
            SegmentStatusMachineEventType.SOURCE_BUFFER_APPENDING,
        );
    },

    markDownloadedVideoSegment(segment) {
        const bufferingSegment = timing.videoBufferData.bufferingRangeMap[segment.id];

        if (!bufferingSegment) return;

        bufferingSegment.status = segmentStatusMachine(
            bufferingSegment.status,
            SegmentStatusMachineEventType.SEGMENT_DOWNLOADED,
        );
    },

    // Audio Buffer Actions

    addAudioBufferingSegment(segment) {
        const bufferingRange = getBufferingRange({
            bufferingSegments: timing[StreamType.AUDIO].bufferingRangeMap,
            bufferedSegments: timing[StreamType.AUDIO].bufferedRangeMap,
            segment,
        });

        if (!bufferingRange) return;

        timing[StreamType.AUDIO].bufferingRangeMap[bufferingRange.id] = bufferingRange;
        timing[StreamType.AUDIO].previousBufferingSegment = bufferingRange;
    },

    addAudioBufferedSegment(segment) {
        addBufferedRange(timing, segment, StreamType.AUDIO);
    },

    dropAudioBufferedRange(start = 0, end = Infinity) {
        dropBufferedRange(timing.audioBufferData, start, end);
    },

    markAppendingAudioSegment(segment) {
        const bufferingSegment = timing.audioBufferData.bufferingRangeMap[segment.id];

        if (!bufferingSegment) return;

        bufferingSegment.status = segmentStatusMachine(
            bufferingSegment.status,
            SegmentStatusMachineEventType.SOURCE_BUFFER_APPENDING,
        );
    },

    markDownloadedAudioSegment(segment) {
        const bufferingSegment = timing.audioBufferData.bufferingRangeMap[segment.id];

        if (!bufferingSegment) return;

        bufferingSegment.status = segmentStatusMachine(
            bufferingSegment.status,
            SegmentStatusMachineEventType.SEGMENT_DOWNLOADED,
        );
    },

    setJoinTime(joinTimeSeconds) {
        timing.joinTimeSeconds = joinTimeSeconds;
    },

    setDeviceClockOffset(deviceClockOffsetMs) {
        timing.deviceClockOffsetMs = deviceClockOffsetMs;
    },

    setDesiredPresentationDelay(desiredPresentationDelaySeconds) {
        timing.desiredPresentationDelaySeconds = desiredPresentationDelaySeconds;
    },

    setAvailabilityTimeOffsetSeconds(availabilityTimeOffsetSeconds) {
        timing.availabilityTimeOffsetSeconds = availabilityTimeOffsetSeconds;
    },
});

export type {TTimingActions};
export {resolveTimingActions};
