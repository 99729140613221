import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureVariantSwitchingMessageId} from './FeatureVariantSwitchingMessageId';

const featureVariantSwitchingMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [FeatureVariantSwitchingMessageId._000_EWMA_TIME_BASED_ESTIMATE_CHANGED]: (bandwidthEstimate: string): string =>
            `EWMA time-based estimate changed to ${bandwidthEstimate}, requesting variant change`,
        [FeatureVariantSwitchingMessageId._001_CRITICAL_BUFFER_THRESHOLD_HIT]: (): string =>
            'Critical buffer threshold hit, dropping to lowest variant',
        [FeatureVariantSwitchingMessageId._002_PLAYBACK_RATE_CHANGED]: (playbackRate: number): string =>
            `Playback rate changed to ${playbackRate}x, requesting variant change`,
        [FeatureVariantSwitchingMessageId._003_ACTIVE_CODEC_PROFILE]: (
            activePeriodId: string,
            activeVariantIndex: number,
            audioCodec: string,
            videoCodec: string,
        ): string =>
            `Period: ${activePeriodId}, Variant: ${activeVariantIndex}, 
            Audio Codec: ${audioCodec}, Video Codec: ${videoCodec}`,
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TFeatureVariantSwitchingMessages = TLoggerMessages<
    MessageNamespace._007_FEATURE_VARIANT_SWITCHING,
    typeof featureVariantSwitchingMessages
>;

export {featureVariantSwitchingMessages};
