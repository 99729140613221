import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {VariantOrchestratorMessageId} from './VariantOrchestratorMessageId';

const variantOrchestratorMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [VariantOrchestratorMessageId._000_MAINTAINING_VARIANT_INDEX]: (variantIndex: number): string =>
            `Maintaining current variant index ${variantIndex}`,
        [VariantOrchestratorMessageId._001_EMERGENCY_VARIANT_REDUCTION]: (): string =>
            'Emergency variant reduction, will abort all in-flight requests',
        [VariantOrchestratorMessageId._002_CHANGING_TO_VARIANT_INDEX]: (variantIndex: number): string =>
            `Changing to variant index ${variantIndex}`,
        [VariantOrchestratorMessageId._003_DROPPING_TO_VARIANT_INDEX]: (variantIndex: number): string =>
            `Dropping to variant index ${variantIndex}`,
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TVariantOrchestratorMessages = TLoggerMessages<
    MessageNamespace._018_VARIANT_ORCHESTRATOR,
    typeof variantOrchestratorMessages
>;

export {variantOrchestratorMessages};
