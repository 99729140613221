import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import {getHumanReadableBitrate} from '@Shared/Util/getHumanReadableBitrate';

import {FeaturePersistedBandwidthEstimateMessageId} from './FeaturePersistedBandwidthEstimateMessageId';

const featurePersistedBandwidthEstimateMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [FeaturePersistedBandwidthEstimateMessageId._000_WRITING_BANDWIDTH_ESTIMATE_TO_LOCAL_STORAGE]: (
            bandwidthEstimate: number,
        ): string =>
            `Writing time-based bandwidth estimate of ${getHumanReadableBitrate(bandwidthEstimate)} to local storage`,
        [FeaturePersistedBandwidthEstimateMessageId._001_READING_BANDWIDTH_ESTIMATE_FROM_LOCAL_STORAGE]: (
            bandwidthEstimate: number,
        ): string =>
            `Reading previous bandwidth estimate of ${getHumanReadableBitrate(bandwidthEstimate)} from local storage`,
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TFeaturePersistedBandwidthEstimateMessages = TLoggerMessages<
    MessageNamespace._024_FEATURE_PERSISTED_BANDWIDTH_ESTIMATE,
    typeof featurePersistedBandwidthEstimateMessages
>;

export {featurePersistedBandwidthEstimateMessages};
