/* eslint-disable max-len */

/**
 * An extension of the basic s = d / t equation, accounting for an increased `t`
 * due to latency (CDN response time).
 *
 * The equation combines the unbounded logarithmic effects of increasing latency,
 * with a linear deterioration to 0 when latency reaches the segment duration.
 *
 * s: speed (bps)
 * l: latency (s)
 * d: segment duration (s)
 *
 * The function below is exactly = f(s, l, d) = MAX(0, (s * d) - (s * l)) / d + l
 * However, which can be simplified to = f(s, l, d) = MAX(0, s * (d - l)) / d + l
 *
 * LaTeX (https://www.codecogs.com/latex/eqneditor.php) formula:
 *
 * f(s, l, d) = \frac{
 *   \max(0, s(d - l))
 * } {
 *   d + l
 * }
 *
 * Expanded logical equation with `MAX` removed:
 *
 * f(s, l, d) = \left\{\begin{matrix}
 *    \frac{
 *        s(d - l)
 *    } {
 *        d + 1
 *   } & d - l > 0 \\
 *   0 & \text{otherwise}
 * \end{matrix}\right.
 *
 * https://latex.codecogs.com/svg.latex?f%28s%2C%20l%2C%20d%29%20%3D%20%5Cleft%5C%7B%5Cbegin%7Bmatrix%7D%20%5Cfrac%7B%20s%28d%20-%20l%29%20%7D%20%7B%20d%20&plus;%201%20%7D%20%26%20d%20-%20l%20%3E%200%20%5C%5C%200%20%26%20%5Ctext%7Botherwise%7D%20%5Cend%7Bmatrix%7D%5Cright.
 */

const estimateLatencyInclusiveBandwidth = (
    bandwidthEstimate: number,
    latencySeconds: number,
    segmentDuration: number,
): number => {
    // The segment duration (in seconds) dictates the unit of "time" we
    // will use for the calculation (normally this is 1 as speed is measured (mb/1s))
    const baseTime = segmentDuration;
    // With latency taken into account, the total time for speed to be measured against is
    // increased by the amount of latency:
    const totalTime = segmentDuration + latencySeconds;
    // For the filesize component of the calculation, we know that `bandwidthEstimate` number of bits
    // can be transferred in 1second, but our base time is the segment duration - not 1s.
    // Firstly, convert the fileSize into a the amount that can be transferred in the base time:
    const baseFileSize = bandwidthEstimate * baseTime;
    // Now calculate the amount of data we will lose due to latency:
    const lostFileSize = bandwidthEstimate * latencySeconds;
    // To calculate the effective file size for the calculation, subtract the two from each
    // other, with a minimum output of `0`.
    const effectiveFileSize = Math.max(0, baseFileSize - lostFileSize);
    // Divide the effective file size by totalTime to yield final effective speed:
    const estimate = effectiveFileSize / totalTime;

    return Math.round(estimate) || 0; // Default to 0 if any previous step resulted in NaN
};

export {estimateLatencyInclusiveBandwidth};
