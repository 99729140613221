import {arrayIncludes} from '@dazn/peng-html5-tools-utils';

import type {TIsCodecSupported} from '../Types/TIsCodecSupported';

/**
 * Extracts all values between two double quotes, consisting of one more:
 * - upper or lower case characters
 * - numbers
 * - a dot or comma
 * - whitespace
 */

const codecsRegExp = /"([a-zA-Z0-9\\.,\s]+)"/;

/**
 * A list of all audio/video codecs MIMEs guaranteed to be supported on a Tizen 2.x device,
 * in combination with an MP4 container.
 *
 * This list was created by cross-referencing the following documents:
 * - https://developer.samsung.com/smarttv/develop/specifications/media-specifications/2016-tv-video-specifications.html
 * - https://developer.samsung.com/smarttv/develop/specifications/media-specifications/2015-tv-video-specifications.html
 * - https://wiki.whatwg.org/wiki/Video_type_parameters#Browser_Support
 */

const supportedCodecsForMp4Container: string[] = [
    // audio
    'mp4a', // AAC
    // video
    'avc1', // H264
    'hev1', // HEVC
    'hvc1', // HEVC
];

/**
 * A implementation of `TIsCodecSupported` for Tizen 2.x.
 *
 * NB: Neither `HTMLVideoElement.prototype.canPlayType()` or `MediaSource.isTypeSupported()`
 * are correctly implemented on these platforms. Both appear to be fake implementations that
 * return a "positive" response for any given input.
 *
 * We therefore fallback to checking against a hard-coded list of codec MIME strings.
 */

const isCodecSupportedViaRegexMatch: TIsCodecSupported = mime => {
    const codecMatch = mime.match(codecsRegExp);

    if (!codecMatch) return false;

    const codecs = codecMatch[1].split(',').map(c => c.trim());

    let isSupported = true;

    for (const codec of codecs) {
        const codecType = codec.split('.')[0];

        if (!arrayIncludes(supportedCodecsForMp4Container, codecType)) {
            isSupported = false;
            break;
        }
    }

    return isSupported;
};

export {isCodecSupportedViaRegexMatch};
