import {MessageNamespace} from '../Constants/MessageNamespace';

const renderHumanReadableMessageNamespace = (namespace: MessageNamespace): string =>
    ({
        [MessageNamespace._001_DEPO]: 'Depo',
        [MessageNamespace._000_DASH_MANIFEST_PARSER]: 'DASH Manifest Parser',
        [MessageNamespace._002_DOWNLOADER]: 'Downloader',
        [MessageNamespace._003_FEATURE_BANDWIDTH_ESTIMATION]: 'Bandwidth Estimation',
        [MessageNamespace._004_FEATURE_CODEC_SWITCHING]: 'Codec Switching',
        [MessageNamespace._005_FEATURE_GAP_JUMPING]: 'Gap Jumping',
        [MessageNamespace._006_FEATURE_MANIFEST_LOADING]: 'Manifest Loading',
        [MessageNamespace._008_FEATURE_MULTI_PERIOD_DASH]: 'Multi-period DASH',
        [MessageNamespace._007_FEATURE_VARIANT_SWITCHING]: 'Variant Switching',
        [MessageNamespace._009_GENERIC]: 'Generic Error',
        [MessageNamespace._011_MEDIA_ELEMENT_CONTROLLER]: 'MediaElementController',
        [MessageNamespace._012_PUBLIC_API]: 'Public API',
        [MessageNamespace._013_SEGMENT_ORCHESTRATOR_AUDIO]: 'SegmentOrchestrator<Audio>',
        [MessageNamespace._014_SEGMENT_ORCHESTRATOR_VIDEO]: 'SegmentOrchestrator<Video>',
        [MessageNamespace._015_SOURCE_BUFFER_MANAGER_AUDIO]: 'SourceBufferManager<Audio>',
        [MessageNamespace._016_SOURCE_BUFFER_MANAGER_VIDEO]: 'SourceBufferManager<Video>',
        [MessageNamespace._017_TRANSMUXER]: 'Transmuxer',
        [MessageNamespace._018_VARIANT_ORCHESTRATOR]: 'VariantOrchestrator',
        [MessageNamespace._019_DRM_CONTROLLER]: 'DrmController',
        [MessageNamespace._020_FEATURE_CODEC_FILTERING]: 'Codec Filtering',
        [MessageNamespace._021_LIFECYCLE_MANAGER]: 'Lifecycle Manager',
        [MessageNamespace._022_DVR_WINDOW_RECOVERY]: 'DVR Window Recovery',
        [MessageNamespace._023_FEATURE_ENDING]: 'Ending',
        [MessageNamespace._024_FEATURE_PERSISTED_BANDWIDTH_ESTIMATE]: 'Persisted Bandwidth Estimate',
        [MessageNamespace._025_FEATURE_STALL_RECOVERY]: 'Stall Recovery',
        [MessageNamespace._026_METRICS_STORE]: 'Metrics Store',
        [MessageNamespace._027_PLAYBACK_QUALITY]: 'Playback Quality',
        [MessageNamespace._028_FEATURE_STARTUP]: 'Startup',
        [MessageNamespace._029_FEATURE_LOCAL_STORAGE]: 'Local Storage',
        [MessageNamespace._030_FEATURE_MULTI_TRACK_AUDIO]: 'Multi-track Audio',
        [MessageNamespace._031_FEATURE_PLAYER_HEALTH]: 'Player Health',
        [MessageNamespace._032_LIVE_LATENCY_MEASUREMENT]: 'Live Latency',
        [MessageNamespace._033_ADAPTIVE_PRESENTATION_DELAY]: 'Adaptive Presentation Delay',
        [MessageNamespace._034_PLAYBACK_RATE]: 'Playback Rate',
    })[namespace];

export {renderHumanReadableMessageNamespace};
