import {msToSeconds} from '@dazn/peng-html5-tools-utils';

import {roundTo3DecimalPlaces} from '@Shared/Util/roundTo3DecimalPlaces';

const getSynchronisedTimeSeconds = (timeSeconds: number, deviceClockOffsetMs: number): number => {
    const deviceClockOffsetSeconds = msToSeconds(deviceClockOffsetMs);
    const syncedTimeSeconds = roundTo3DecimalPlaces(timeSeconds + deviceClockOffsetSeconds);

    return syncedTimeSeconds;
};

export {getSynchronisedTimeSeconds};
