import type {State} from '@dazn/depo';
import {createState as createDepoState} from '@dazn/depo';
import type {BehaviorSubject} from 'rxjs';

import {readFromLocalStorage} from '@Features/LocalStorage/Util/readFromLocalStorage';
import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import type {TDeepPartial} from '@Shared/Types/TDeepPartial';

import {DepoMessageId} from './Constants/DepoMessageId';
import type {IPlayerValue} from './Interfaces/IPlayerValue';
import {createConfigStore} from './Stores/Config/createConfigStore';
import {createLocalStorageStore} from './Stores/LocalStorage/createLocalStorageStore';
import {createManifestStore} from './Stores/Manifest/createManifestStore';
import {createMetricsStore} from './Stores/Metrics/createMetricsStore';
import type {TPlayerStores} from './Types/TPlayerStores';
import {parseDepoLog} from './Util/parseDepoLog';
import {readInitialMetricsValueFromLocalStorage} from './Util/readInitialMetricsValueFromLocalStorage';

const createState = (
    initialState: TDeepPartial<IPlayerValue> = {},
): State<TPlayerStores, IPlayerValue, BehaviorSubject<IPlayerValue>> => {
    const state = createDepoState<TPlayerStores, IPlayerValue, BehaviorSubject<IPlayerValue>>({
        log: msg => {
            const {storeName, actionName, wasBatchUpdate, wasLinkedUpdate, fallback} = parseDepoLog(msg);

            logger.info(
                MessageNamespace._001_DEPO,
                DepoMessageId._000_STATE_UPDATE,
                storeName,
                actionName,
                wasBatchUpdate,
                wasLinkedUpdate,
                fallback,
            );
        },
        maxHistoryLength: 1,
    });

    createConfigStore(state, initialState.config);

    const initialLocalStorageValue = readFromLocalStorage();
    const localStorageStore = createLocalStorageStore(state, initialLocalStorageValue);
    const initialMetricsValue = readInitialMetricsValueFromLocalStorage(localStorageStore);

    createManifestStore(state);
    createMetricsStore(state, initialMetricsValue);

    return state;
};

export {createState};
