import {DEFAULT_LANGUAGE} from '@Features/MultiTrackAudio/Constants/languages';

import {AssetType} from '../Constants/AssetType';
import {ContainerFormat} from '../Constants/ContainerFormat';
import {SegmentStatus} from '../Constants/SegmentStatus';

class Segment {
    public bitrate: number = -1;
    public containerFormat: ContainerFormat = ContainerFormat.UNSET;
    public durationSeconds: number = -1;
    public endTimeSeconds: number = -1;
    public framerate: number = -1;
    public initializationSegmentUrl: string = '';
    public isFinalSegment: boolean = false;
    public language: string = DEFAULT_LANGUAGE;
    public mediaTimeOffsetSeconds: number = 0;
    public periodId: string = '';
    public segmentIndex: number = -1;
    public segmentRangeIndex: number = -1;
    public startTimeSeconds: number = -1;
    public startTimeTimescaleUnits: number = -1;
    public status = SegmentStatus.UNSET;
    public timelineShift: number = 0;
    public timescale: number = -1;
    public type: AssetType.AUDIO_SEGMENT | AssetType.VIDEO_SEGMENT = AssetType.AUDIO_SEGMENT;
    public url: string = '';
    public params: Record<string, string> = {};
    public variantIndex: number = -1;

    public get isMp4(): boolean {
        return this.containerFormat === ContainerFormat.MP4;
    }

    /**
     * startTimeTimescaleUnits are NOT unique across periods, i.e., most periods
     * will have a startTimeTimescaleUnits of 0 - this can result in duplicate
     * startTimeTimescaleUnits values in the buffering or buffered ranges when
     * playing multiple short periods (< 6s), e.g., a repeated ad slate. Other
     * properties ensure ids remain unique, whilst allowing us to play the same
     * URL multiple times.
     */

    public get id(): string {
        return `${this.periodId}_${this.variantIndex}_${this.language}_${this.startTimeTimescaleUnits}`;
    }
}

export {Segment};
