import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeaturePlaybackRateMessageId} from './FeaturePlaybackRateMessageId';

const playbackRateMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {},
    [MessageType.WARNING]: {
        [FeaturePlaybackRateMessageId._000_UNEXPECTED_PLAYBACK_RATE_CHANGE]: (playbackRate: number): string =>
            `Unexpected playback rate change to ${playbackRate} despite no playback rate support`,
    },
    [MessageType.ERROR]: {},
};

export type TFeaturePlaybackRateMessages = TLoggerMessages<
    MessageNamespace._034_PLAYBACK_RATE,
    typeof playbackRateMessages
>;

export {playbackRateMessages};
