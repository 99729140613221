import type {State, Store} from '@dazn/depo';
import {BehaviorSubject} from 'rxjs';

import type {TDeepPartial} from '@Shared/Types/TDeepPartial';

import {StoreName} from '../../Constants/StoreName';

import {resolveConfigActions} from './Actions/resolveConfigActions';
import {Config} from './Models/Config';

const createConfigStore = (
    state: State,
    initialValue: TDeepPartial<Config> = {},
): Store<Config, typeof resolveConfigActions, BehaviorSubject<Config>> =>
    state.createStore(
        {
            model: Config,
            name: StoreName.CONFIG,
            actions: resolveConfigActions,
            observable: BehaviorSubject,
        },
        initialValue,
    );

export {createConfigStore};
