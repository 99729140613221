import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureBandwidthEstimationMessageId} from './FeatureBandwidthEstimationMessageId';

const featureBandwidthEstimationMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [FeatureBandwidthEstimationMessageId._000_PLUMMETING_BUFFER]: (): string =>
            'Plummeting buffer detected, re-seeding EWMA time-based estimate',
        [FeatureBandwidthEstimationMessageId._001_THROUGHPUT_BASED_ESTIMATE_CHANGED]: (
            bandwidthEstimate: string,
        ): string => `Throughput-based estimate changed to ${bandwidthEstimate}`,
        [FeatureBandwidthEstimationMessageId._002_LATENCY_ESTIMATE_CHANGED]: (latencyEstimate: string): string =>
            `Latency estimate changed to ${latencyEstimate}`,
        [FeatureBandwidthEstimationMessageId._003_TIME_BASED_ESTIMATE_CHANGED]: (bandwidthEstimate: string): string =>
            `Time-based estimate changed to ${bandwidthEstimate}`,
        [FeatureBandwidthEstimationMessageId._004_PROLONGED_BUFFERING]: (): string =>
            'Prolonged buffering detected, re-seeding EWMA time-based estimate',
    },
    [MessageType.WARNING]: {
        [FeatureBandwidthEstimationMessageId._005_RESEEDING_PREVENTED]: (): string =>
            'Could not re-seed throughput-based estimate because no time-based estimate available',
    },
    [MessageType.ERROR]: {},
};

export type TFeatureBandwidthEstimationMessages = TLoggerMessages<
    MessageNamespace._003_FEATURE_BANDWIDTH_ESTIMATION,
    typeof featureBandwidthEstimationMessages
>;

export {featureBandwidthEstimationMessages};
