import * as DrmControllerMessageIdModule from '@DrmController/Constants/DrmControllerMessageId';
import {drmControllerMessages} from '@DrmController/Constants/drmControllerMessages';
import {adaptivePresentationDelayMessages} from '@Features/AdaptivePresentationDelay/Constants/adaptivePresentationDelayMessages';
import * as FeatureAdaptivePresentationDelayMessageIdModule from '@Features/AdaptivePresentationDelay/Constants/FeatureAdaptivePresentationDelayMessageId';
import * as FeatureBandwidthEstimationMessageIdModule from '@Features/BandwidthEstimation/Constants/FeatureBandwidthEstimationMessageId';
import {featureBandwidthEstimationMessages} from '@Features/BandwidthEstimation/Constants/featureBandwidthEstimationMessages';
import * as FeatureCodecFilteringMessageIdModule from '@Features/CodecFiltering/Constants/FeatureCodecFilteringMessageId';
import {featureCodecFilteringMessages} from '@Features/CodecFiltering/Constants/featureCodecFilteringMessages';
import * as FeatureCodecSwitchingMessageIdModule from '@Features/CodecSwitching/Constants/FeatureCodecSwitchingMessageId';
import {featureCodecSwitchingMessages} from '@Features/CodecSwitching/Constants/featureCodecSwitchingMessages';
import * as DvrWindowRecoveryMessageIdModule from '@Features/DvrWindowRecovery/Constants/DvrWindowRecoveryMessageId';
import {dvrWindowRecoveryMessages} from '@Features/DvrWindowRecovery/Constants/dvrWindowRecoveryMessages';
import * as FeatureEndingMessageIdModule from '@Features/Ending/Constants/FeatureEndingMessageId';
import {featureEndingMessages} from '@Features/Ending/Constants/featureEndingMessages';
import * as FeatureGapJumpingMessageIdModule from '@Features/GapJumping/Constants/FeatureGapJumpingMessageId';
import {featureGapJumpingMessages} from '@Features/GapJumping/Constants/featureGapJumpingMessages';
import * as FeatureLiveLatencyMeasurementMessageIdModule from '@Features/LiveLatencyMeasurement/Constants/FeatureLiveLatencyMeasurementMessageId';
import {liveLatencyMeasurementMessages} from '@Features/LiveLatencyMeasurement/Constants/liveLatencyMeasurementMessages';
import * as FeatureLocalStorageMessageIdModule from '@Features/LocalStorage/Constants/FeatureLocalStorageMessageId';
import {featureLocalStorageMessages} from '@Features/LocalStorage/Constants/featureLocalStorageMessages';
import * as FeatureManifestLoadingMessageIdModule from '@Features/ManifestLoading/Constants/FeatureManifestLoadingMessageId';
import {featureManifestLoadingMessages} from '@Features/ManifestLoading/Constants/featureManifestLoadingMessages';
import * as FeatureMultiPeriodDashMessageIdModule from '@Features/MultiPeriodDash/Constants/FeatureMultiPeriodDashMessageId';
import {featureMultiPeriodDashMessages} from '@Features/MultiPeriodDash/Constants/featureMultiPeriodDashMessages';
import * as FeatureMultiTrackAudioMessageIdModule from '@Features/MultiTrackAudio/Constants/FeatureMultiTrackAudioMessageId';
import {featureMultiTrackAudioMessages} from '@Features/MultiTrackAudio/Constants/featureMultiTrackAudioMessages';
import * as FeaturePersistedBandwidthEstimateMessageIdModule from '@Features/PersistedBandwidthEstimate/Constants/FeaturePersistedBandwidthEstimateMessageId';
import {featurePersistedBandwidthEstimateMessages} from '@Features/PersistedBandwidthEstimate/Constants/featurePersistedBandwidthEstimateMessages';
import * as FeaturePlaybackQualityMessageIdModule from '@Features/PlaybackQuality/Constants/FeaturePlaybackQualityMessageId';
import {featurePlaybackQualityMessages} from '@Features/PlaybackQuality/Constants/featurePlaybackQualityMessages';
import * as FeaturePlaybackRateMessageIdModule from '@Features/PlaybackRate/Constants/FeaturePlaybackRateMessageId';
import {playbackRateMessages} from '@Features/PlaybackRate/Constants/playbackRateMessages';
import * as FeaturePlayerHealthMessageIdModule from '@Features/PlayerHealth/Constants/FeaturePlayerHealthMessageId';
import {featurePlayerHealthMessages} from '@Features/PlayerHealth/Constants/featurePlayerHealthMessages';
import * as FeatureStallRecoveryMessageIdModule from '@Features/StallRecovery/Constants/FeatureStallRecoveryMessageId';
import {featureStallRecoveryMessages} from '@Features/StallRecovery/Constants/featureStallRecoveryMessages';
import * as FeatureStartupMessageIdModule from '@Features/Startup/Constants/FeatureStartupMessageId';
import {featureStartupMessages} from '@Features/Startup/Constants/featureStartupMessages';
import * as FeatureVariantSwitchingMessageIdModule from '@Features/VariantSwitching/Constants/FeatureVariantSwitchingMessageId';
import {featureVariantSwitchingMessages} from '@Features/VariantSwitching/Constants/featureVariantSwitchingMessages';
import * as LifecycleManagerMessageIdModule from '@LifecycleManager/Constants/LifecycleManagerMessageId';
import {lifecycleManagerMessages} from '@LifecycleManager/Constants/lifecycleManagerMessages';
import * as DashManifestParserMessageIdModule from '@ManifestParsers/Dash/Constants/DashManifestParserMessageId';
import {dashManifestParserMessages} from '@ManifestParsers/Dash/Constants/dashManifestParserMessages';
import * as MediaElementControllerMessageIdModule from '@MediaElementController/Constants/MediaElementControllerMessageId';
import {mediaElementControllerMessages} from '@MediaElementController/Constants/mediaElementControllerMessages';
import * as DownloaderMessageIdModule from '@Network/Constants/DownloaderMessageId';
import {downloaderMessages} from '@Network/Constants/downloaderMessages';
import * as PublicApiMessageIdModule from '@Player/Constants/PublicApiMessageId';
import {publicApiMessages} from '@Player/Constants/publicApiMessages';
import * as SegmentOrchestratorMessageIdModule from '@SegmentOrchestrator/Constants/SegmentOrchestratorMessageId';
import {segmentOrchestratorMessages} from '@SegmentOrchestrator/Constants/segmentOrchestratorMessages';
import * as GenericMessageIdModule from '@Shared/Constants/GenericMessageId';
import {genericMessages} from '@Shared/Constants/genericMessages';
import * as SourceBufferManagerMessageIdModule from '@SourceBufferManager/Constants/SourceBufferManagerMessageId';
import {sourceBufferManagerMessages} from '@SourceBufferManager/Constants/sourceBufferManagerMessages';
import * as TransmuxerMessageIdModule from '@SourceBufferManager/Constants/TransmuxerMessageId';
import {transmuxerMessages} from '@SourceBufferManager/Constants/transmuxerMessages';
import * as DepoMessageIdModule from '@State/Constants/DepoMessageId';
import {depoMessages} from '@State/Constants/depoMessages';
import * as MetricsStoreMessageIdModule from '@State/Stores/Metrics/Constants/MetricsStoreMessageId';
import {metricsStoreMessages} from '@State/Stores/Metrics/Constants/metricsStoreMessages';
import * as VariantOrchestratorMessageIdModule from '@VariantOrchestrator/Constants/VariantOrchestratorMessageId';
import {variantOrchestratorMessages} from '@VariantOrchestrator/Constants/variantOrchestratorMessages';

import {MessageNamespace} from '../Constants/MessageNamespace';
import type {MessageType} from '../Constants/MessageType';
import type {TMessageId} from '../Types/TMessageId';

/**
 * A lookup table of all human readable "log", "warning", and "error" message for debugging. As this type of
 * text is heavy and does not minify well, it is extracted from the default build and loaded as needed as
 * part of the `DebugView`.
 *
 * Logs are still produced regardless at all times, but are stored as terse numeric values which are mapped
 * into their human readable equivalents via this lookup table only when the `DebugView` is activated.
 */

type TMessagesBySeverity = Record<MessageType, Record<string, (...args: any[]) => string>>;

type TMercuryMessages = Record<
    MessageNamespace,
    {messages: TMessagesBySeverity; ids: {[namedExport: string]: Record<string, number> | Record<number, string>}}
>;

const messageLookupTable: TMercuryMessages = {
    [MessageNamespace._000_DASH_MANIFEST_PARSER]: {
        messages: dashManifestParserMessages,
        ids: DashManifestParserMessageIdModule,
    },
    [MessageNamespace._001_DEPO]: {messages: depoMessages, ids: DepoMessageIdModule},
    [MessageNamespace._002_DOWNLOADER]: {messages: downloaderMessages, ids: DownloaderMessageIdModule},
    [MessageNamespace._003_FEATURE_BANDWIDTH_ESTIMATION]: {
        messages: featureBandwidthEstimationMessages,
        ids: FeatureBandwidthEstimationMessageIdModule,
    },
    [MessageNamespace._004_FEATURE_CODEC_SWITCHING]: {
        messages: featureCodecSwitchingMessages,
        ids: FeatureCodecSwitchingMessageIdModule,
    },
    [MessageNamespace._005_FEATURE_GAP_JUMPING]: {
        messages: featureGapJumpingMessages,
        ids: FeatureGapJumpingMessageIdModule,
    },
    [MessageNamespace._006_FEATURE_MANIFEST_LOADING]: {
        messages: featureManifestLoadingMessages,
        ids: FeatureManifestLoadingMessageIdModule,
    },
    [MessageNamespace._008_FEATURE_MULTI_PERIOD_DASH]: {
        messages: featureMultiPeriodDashMessages,
        ids: FeatureMultiPeriodDashMessageIdModule,
    },
    [MessageNamespace._007_FEATURE_VARIANT_SWITCHING]: {
        messages: featureVariantSwitchingMessages,
        ids: FeatureVariantSwitchingMessageIdModule,
    },
    [MessageNamespace._009_GENERIC]: {messages: genericMessages, ids: GenericMessageIdModule},
    [MessageNamespace._011_MEDIA_ELEMENT_CONTROLLER]: {
        messages: mediaElementControllerMessages,
        ids: MediaElementControllerMessageIdModule,
    },
    [MessageNamespace._012_PUBLIC_API]: {messages: publicApiMessages, ids: PublicApiMessageIdModule},
    [MessageNamespace._013_SEGMENT_ORCHESTRATOR_AUDIO]: {
        messages: segmentOrchestratorMessages,
        ids: SegmentOrchestratorMessageIdModule,
    },
    [MessageNamespace._014_SEGMENT_ORCHESTRATOR_VIDEO]: {
        messages: segmentOrchestratorMessages,
        ids: SegmentOrchestratorMessageIdModule,
    },
    [MessageNamespace._015_SOURCE_BUFFER_MANAGER_AUDIO]: {
        messages: sourceBufferManagerMessages,
        ids: SourceBufferManagerMessageIdModule,
    },
    [MessageNamespace._016_SOURCE_BUFFER_MANAGER_VIDEO]: {
        messages: sourceBufferManagerMessages,
        ids: SourceBufferManagerMessageIdModule,
    },
    [MessageNamespace._017_TRANSMUXER]: {messages: transmuxerMessages, ids: TransmuxerMessageIdModule},
    [MessageNamespace._018_VARIANT_ORCHESTRATOR]: {
        messages: variantOrchestratorMessages,
        ids: VariantOrchestratorMessageIdModule,
    },
    [MessageNamespace._019_DRM_CONTROLLER]: {messages: drmControllerMessages, ids: DrmControllerMessageIdModule},
    [MessageNamespace._020_FEATURE_CODEC_FILTERING]: {
        messages: featureCodecFilteringMessages,
        ids: FeatureCodecFilteringMessageIdModule,
    },
    [MessageNamespace._021_LIFECYCLE_MANAGER]: {
        messages: lifecycleManagerMessages,
        ids: LifecycleManagerMessageIdModule,
    },
    [MessageNamespace._022_DVR_WINDOW_RECOVERY]: {
        messages: dvrWindowRecoveryMessages,
        ids: DvrWindowRecoveryMessageIdModule,
    },
    [MessageNamespace._023_FEATURE_ENDING]: {messages: featureEndingMessages, ids: FeatureEndingMessageIdModule},
    [MessageNamespace._024_FEATURE_PERSISTED_BANDWIDTH_ESTIMATE]: {
        messages: featurePersistedBandwidthEstimateMessages,
        ids: FeaturePersistedBandwidthEstimateMessageIdModule,
    },
    [MessageNamespace._025_FEATURE_STALL_RECOVERY]: {
        messages: featureStallRecoveryMessages,
        ids: FeatureStallRecoveryMessageIdModule,
    },
    [MessageNamespace._026_METRICS_STORE]: {messages: metricsStoreMessages, ids: MetricsStoreMessageIdModule},
    [MessageNamespace._027_PLAYBACK_QUALITY]: {
        messages: featurePlaybackQualityMessages,
        ids: FeaturePlaybackQualityMessageIdModule,
    },
    [MessageNamespace._028_FEATURE_STARTUP]: {messages: featureStartupMessages, ids: FeatureStartupMessageIdModule},
    [MessageNamespace._029_FEATURE_LOCAL_STORAGE]: {
        messages: featureLocalStorageMessages,
        ids: FeatureLocalStorageMessageIdModule,
    },
    [MessageNamespace._030_FEATURE_MULTI_TRACK_AUDIO]: {
        messages: featureMultiTrackAudioMessages,
        ids: FeatureMultiTrackAudioMessageIdModule,
    },
    [MessageNamespace._031_FEATURE_PLAYER_HEALTH]: {
        messages: featurePlayerHealthMessages,
        ids: FeaturePlayerHealthMessageIdModule,
    },
    [MessageNamespace._032_LIVE_LATENCY_MEASUREMENT]: {
        messages: liveLatencyMeasurementMessages,
        ids: FeatureLiveLatencyMeasurementMessageIdModule,
    },
    [MessageNamespace._033_ADAPTIVE_PRESENTATION_DELAY]: {
        messages: adaptivePresentationDelayMessages,
        ids: FeatureAdaptivePresentationDelayMessageIdModule,
    },
    [MessageNamespace._034_PLAYBACK_RATE]: {
        messages: playbackRateMessages,
        ids: FeaturePlaybackRateMessageIdModule,
    },
};

const renderHumanReadableMessage = (
    messageType: MessageType,
    namespace: MessageNamespace,
    logId: TMessageId,
    args: any[],
): string => {
    const render = messageLookupTable[namespace].messages[messageType][logId];

    return render(...args);
};

export type {TMercuryMessages};
export {renderHumanReadableMessage, messageLookupTable};
