import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {MediaElementReadyState} from '@Shared/Constants/MediaElementReadyState';

import {MediaElementControllerMessageId} from './MediaElementControllerMessageId';
import type {MediaElementEventType} from './MediaElementEventType';

const createPlaybackQualityReportMessage =
    (qualifier: string) =>
    (
        droppedVideoFrames: number,
        totalVideoFrames: number,
        videoFrameFailureRate: number,
        droppedVideoFrameToleranceThreshold: number,
    ) =>
        `Playback quality ${qualifier} ${droppedVideoFrameToleranceThreshold * 100}% threshold:\n` +
        `    Video frame failure rate: ${videoFrameFailureRate * 100}%\n` +
        `    Dropped video frames:     ${droppedVideoFrames}\n` +
        `    Total video frames:       ${totalVideoFrames}\n`;

const mediaElementControllerMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [MediaElementControllerMessageId._000_CURRENT_TIME_SET]: (currentTime: number): string =>
            `Video element current time set to "${currentTime}"`,
        [MediaElementControllerMessageId._001_END_OF_STREAM]: (): string => 'Stream ended on media source',
        [MediaElementControllerMessageId._002_MEDIA_SOURCE_ATTACHED]: (): string => 'Media source attached',
        [MediaElementControllerMessageId._003_MEDIA_SOURCE_DETACHED]: (): string => 'Media source detached',
        [MediaElementControllerMessageId._004_MEDIA_SOURCE_DURATION_SET]: (duration: number): string =>
            `Media source duration set to "${duration}"`,
        [MediaElementControllerMessageId._006_MEDIA_SOURCE_OPEN]: (): string =>
            'Media source open and ready to receive data',
        [MediaElementControllerMessageId._007_PLAY_CALLED]: (): string => 'Video element `.play()` method called',
        [MediaElementControllerMessageId._011_CLAMPING_SEEK_TO_WITHIN_SEEK_RANGE]: (
            requestedSeekToTime: number,
            clampedSeekToTime: number,
        ): string =>
            `Clamping requested seek time ${requestedSeekToTime}s to ${clampedSeekToTime}s to remain within seek range`,
        [MediaElementControllerMessageId._012_CAN_PLAY]: (): string => 'Can play',
        [MediaElementControllerMessageId._013_PLAYBACK_RATE_CHANGE]: (playbackRate: number): string =>
            `Playback rate changed to ${playbackRate}x`,
        [MediaElementControllerMessageId._014_DOCUMENT_BACKGROUNDED]: (): string =>
            'Pausing playback due to the player tab/window being fully hidden and muted',
        [MediaElementControllerMessageId._015_DOCUMENT_FOREGROUNDED]: (): string =>
            'Resuming playback due to the player tab/window being revealed after backgrounding',
        [MediaElementControllerMessageId._016_TIME_UPDATE]: (
            currentTimeSeconds: number,
            seekRangeStartTimeSeconds: number,
            seekRangeEndTimeSeconds: number,
            audioBufferAheadDurationSeconds: number,
            videoBufferAheadDurationSeconds: number,
            distanceFromSeekRangeEndSeconds: number,
            didSeek: boolean,
            timeUpdateFrequencySeconds: number,
        ): string =>
            `Media element \`currentTime\` updated due to ${didSeek ? 'seeking' : 'playback'}:\n` +
            `    Current time:                 ${currentTimeSeconds}s\n` +
            `    Seek range start:             ${seekRangeStartTimeSeconds}s\n` +
            `    Seek range end:               ${seekRangeEndTimeSeconds}s\n` +
            `    Audio buffer ahead duration:  ${audioBufferAheadDurationSeconds}s\n` +
            `    Video buffer ahead duration:  ${videoBufferAheadDurationSeconds}s\n` +
            `    Distance from seek range end: ${distanceFromSeekRangeEndSeconds}s\n` +
            `    Presentation time:            ${currentTimeSeconds - seekRangeStartTimeSeconds}s\n` +
            `    Presentation duration:        ${seekRangeEndTimeSeconds - seekRangeStartTimeSeconds}s\n` +
            `    Update frequency:             ${timeUpdateFrequencySeconds}s`,
        [MediaElementControllerMessageId._020_PLAYBACK_QUALITY_WITHIN_THRESHOLD]:
            createPlaybackQualityReportMessage('within'),
        [MediaElementControllerMessageId._023_MEDIA_ELEMENT_READY_STATE_CHANGE]: (
            readyState: MediaElementReadyState,
        ): string => `Media element readyState change to: ${readyState}`,
        [MediaElementControllerMessageId._025_ANY_UNEXPECTED_SOURCE_BUFFER_UPDATE_IN_PROGRESS]: (): string =>
            'An active source buffer is in an updating state, awaiting idle...',
    },
    [MessageType.WARNING]: {
        [MediaElementControllerMessageId._008_CURRENT_TIME_NOT_ADVANCING]: (
            currentTimeSeconds: number,
            intervalMs: number,
        ): string =>
            `Stall detected at ${currentTimeSeconds}s. Current time did not advance for at least ${intervalMs}ms`,
        [MediaElementControllerMessageId._018_MEDIA_ELEMENT_STALL]: (currentTimeSeconds: number): string =>
            `Native \`stall\` event dispatched at ${currentTimeSeconds}s`,
        [MediaElementControllerMessageId._019_MEDIA_ELEMENT_WAITING]: (
            currentTimeSeconds: number,
            bufferStatus: MediaElementEventType,
            bufferedRanges: string,
        ): string =>
            `Media element waiting:\n` +
            `    Current time:      ${currentTimeSeconds}s\n` +
            `    Buffer status:     ${bufferStatus}\n` +
            `    Buffered ranges:   ${bufferedRanges}`,
        [MediaElementControllerMessageId._021_PLAYBACK_QUALITY_OUTSIDE_THRESHOLD]:
            createPlaybackQualityReportMessage('outside of'),
        [MediaElementControllerMessageId._024_MEDIA_ELEMENT_EXCEPTION_AFTER_TEAR_DOWN]: () =>
            `A media element error was dispatched but the media source was already detached. Ignoring.`,
        [MediaElementControllerMessageId._026_RESET_CURRENT_TIME_ON_LOADEDDATA_AFTER_NO_CAN_PLAY]: (
            currentTime: number,
            pollIntervalMs: number,
        ) =>
            `No "canplay" event seen after "loadeddata" even, resetting current time to ${currentTime}s ` +
            `to wake the video element. Polling for "canplay" event every ${pollIntervalMs}ms...`,
        [MediaElementControllerMessageId._027_MEDIA_ELEMENT_ERROR_DURING_TEAR_DOWN]: (
            code: number,
            message: string,
        ): string => {
            const codePart = code > -1 ? ` Code: "${code}"` : '';
            const messagePart = message ? ` Message: "${message}"` : '';

            return `Media element error during tear down.${codePart}${messagePart}`;
        },
        [MediaElementControllerMessageId._028_IGNORING_VIDEO_ELEMENT_ENDED_EVENT]: () =>
            `Ignoring the ended event, relying on the synthetic ended event logic instead`,
        [MediaElementControllerMessageId._029_TRIGGERING_RESYNC]: (rateChanges: number) =>
            `Triggering an audio/video re-sync after ${rateChanges} changes to the playback rate`,
    },
    [MessageType.ERROR]: {
        [MediaElementControllerMessageId._005_MEDIA_SOURCE_NOT_ATTACHED]: (): string => 'Media source not attached',
        [MediaElementControllerMessageId._009_MEDIA_ELEMENT_ERROR]: (code: number, message: string): string => {
            const codePart = code > -1 ? ` Code: "${code}"` : '';
            const messagePart = message ? ` Message: "${message}"` : '';

            return `Media element error.${codePart}${messagePart}`;
        },
        [MediaElementControllerMessageId._010_MEDIA_SOURCE_NOT_DETACHED]: (): string =>
            'The attached media source must be detached before the media element controller can be destroyed',
        [MediaElementControllerMessageId._017_MEDIA_ELEMENT_EXCEPTION_SET_CURRENT_TIME]: (
            messageOrException: string,
        ): string => `Media element exception while setting \`currentTime\`: ${messageOrException}`,
        [MediaElementControllerMessageId._022_MEDIA_ELEMENT_EXCEPTION_SET_DURATION]: (
            messageOrException: string,
        ): string => `Media element exception while setting \`duration\`: ${messageOrException}`,
    },
};

export type TMediaElementControllerMessages = TLoggerMessages<
    MessageNamespace._011_MEDIA_ELEMENT_CONTROLLER,
    typeof mediaElementControllerMessages
>;

export {mediaElementControllerMessages};
