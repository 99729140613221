import {AssetType} from '@Shared/Constants/AssetType';
import type {Manifest} from '@Shared/Models/Manifest';
import {hasElements} from '@Shared/Util/hasElements';

import type {TFinalSegmentPath} from '../../ManifestParsers/Dash/Types/TFinalSegmentPath';

const parseFinalSegmentPath = (
    manifest: Manifest,
    assetType: AssetType.AUDIO_SEGMENT | AssetType.VIDEO_SEGMENT,
): TFinalSegmentPath => {
    const lastPeriodIndex = manifest.periods.length - 1;
    const lastPeriod = manifest.periods[lastPeriodIndex];

    if (!lastPeriod || !hasElements(lastPeriod.variants)) return [-1, -1, -1];

    const firstVariantOfLastPeriod = lastPeriod.variants[0];

    const segmentRanges = {
        [AssetType.AUDIO_SEGMENT]: firstVariantOfLastPeriod.audioSegmentRanges,
        [AssetType.VIDEO_SEGMENT]: firstVariantOfLastPeriod.videoSegmentRanges,
    }[assetType];

    const hasSegmentRanges = hasElements(segmentRanges);
    const lastSegmentRangeIndex = hasSegmentRanges ? segmentRanges.length - 1 : 0;
    const lastSegmentRange = segmentRanges[lastSegmentRangeIndex];
    const lastSegmentIndex = hasSegmentRanges ? lastSegmentRange.segmentCount - 1 + lastSegmentRange.startIndex : -1;

    return [lastPeriodIndex, lastSegmentRangeIndex, lastSegmentIndex];
};

export {parseFinalSegmentPath};
