import {joinUrlSegments} from '@Shared/Util/joinUrlSegments';

// eslint-disable-next-line camelcase
const PUBLIC_PATH = typeof __webpack_public_path__ !== 'undefined' ? __webpack_public_path__ : '!!NON_WEBPACK_BUILD!!';

/**
 * All configuration related to the user of web workers for manifest parsing.
 *
 * @title Web Workers
 */

class ConfigWorkers {
    /**
     * The path at which the HLS manifest parser worker should be downloaded from,
     * relative to the document URL.
     */

    public pathManifestParserWorker = './mercury.worker.dash-manifest-parser.js';

    constructor() {
        if (!PUBLIC_PATH) return;

        // If a webpack public path exists, prepend it to the default
        // workers paths to ensure they are loaded from the same WDS domain
        // as the core

        const options = {addTrailingSlash: false};

        this.pathManifestParserWorker = joinUrlSegments([PUBLIC_PATH, this.pathManifestParserWorker], options);
    }
}

export {ConfigWorkers};
