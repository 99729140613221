import {SegmentStatus} from '@Shared/Constants/SegmentStatus';

import {SegmentStatusMachineEventType} from '../Constants/SegmentStatusMachineEventType';

const segmentStatusMachine = (
    prevSegmentStatus: SegmentStatus,
    eventType: SegmentStatusMachineEventType,
): SegmentStatus => {
    switch (prevSegmentStatus) {
        case SegmentStatus.APPENDING:
            switch (eventType) {
                case SegmentStatusMachineEventType.SOURCE_BUFFER_APPENDED:
                    return SegmentStatus.APPENDED;
                default:
                    return prevSegmentStatus;
            }

        case SegmentStatus.AWAITING_APPEND:
            switch (eventType) {
                case SegmentStatusMachineEventType.SOURCE_BUFFER_APPENDING:
                    return SegmentStatus.APPENDING;
                default:
                    return prevSegmentStatus;
            }

        case SegmentStatus.DOWNLOADING:
            switch (eventType) {
                case SegmentStatusMachineEventType.SEGMENT_DOWNLOADED:
                    return SegmentStatus.AWAITING_APPEND;
                case SegmentStatusMachineEventType.SOURCE_BUFFER_APPENDED:
                    return SegmentStatus.APPENDED;
                default:
                    return prevSegmentStatus;
            }

        case SegmentStatus.UNSET:
            switch (eventType) {
                case SegmentStatusMachineEventType.SEGMENT_REQUESTED:
                    return SegmentStatus.DOWNLOADING;
                default:
                    return prevSegmentStatus;
            }

        default:
            return prevSegmentStatus;
    }
};

export {segmentStatusMachine};
