import type {StreamType} from '@Shared/Constants/StreamType';
import {Segment} from '@Shared/Models/Segment';
import {createModelOfType} from '@Shared/Util/createModelOfType';

import {SegmentStatusMachineEventType} from '../Constants/SegmentStatusMachineEventType';
import type {Timing} from '../Models/Timing';
import {segmentStatusMachine} from '../StateMachines/segmentStatusMachine';

const addBufferedRange = (timing: Timing, bufferedRange: Segment, streamType: StreamType): void => {
    delete timing[streamType].bufferingRangeMap[bufferedRange.id];

    const appendedBufferedRange = createModelOfType(Segment, bufferedRange);

    appendedBufferedRange.status = segmentStatusMachine(
        appendedBufferedRange.status,
        SegmentStatusMachineEventType.SOURCE_BUFFER_APPENDED,
    );

    timing[streamType].bufferedRangeMap[bufferedRange.id] = appendedBufferedRange;
};

export {addBufferedRange};
