import {ConfigAbr} from './ConfigAbr';
import {ConfigAudio} from './ConfigAudio';
import {ConfigBehaviour} from './ConfigBehaviour';
import {ConfigDrm} from './ConfigDrm';
import {ConfigPlayerHealth} from './ConfigPlayerHealth';
import {ConfigRetry} from './ConfigRetry';
import {ConfigStallRecovery} from './ConfigStallRecovery';
import {ConfigWorkers} from './ConfigWorkers';

class Config {
    /**
     * All configuration related to ABR.
     */
    public abr = new ConfigAbr();

    /**
     * All configuration related to audio.
     */
    public audio = new ConfigAudio();

    /**
     * All configuration related to general player behaviour.
     */
    public behaviour = new ConfigBehaviour();

    /**
     * All configuration related to DRM.
     */
    public drm = new ConfigDrm();

    /**
     * All configuration related to player health reporting
     */
    public playerHealth = new ConfigPlayerHealth();

    /**
     * All configuration related to retrying network requests.
     */
    public retry = new ConfigRetry();

    /**
     * All configuration related to recovering from stalled playback
     */
    public stallRecovery = new ConfigStallRecovery();

    /**
     * All configuration related to web workers.
     */
    public workers = new ConfigWorkers();
}

export {Config};
