import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureCodecSwitchingMessageId} from './FeatureCodecSwitchingMessageId';

const featureCodecSwitchingMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {
        [FeatureCodecSwitchingMessageId._000_REPLACING_MEDIA_PIPELINE]: (periodId: string): string =>
            `Pulling down media source on entry to period "${periodId}"...`,
        [FeatureCodecSwitchingMessageId._001_MEDIA_PIPELINE_REPLACED]: (): string =>
            'Media pipeline replaced and all buffered data behind current time dropped',
    },
    [MessageType.WARNING]: {},
    [MessageType.ERROR]: {},
};

export type TFeatureCodecSwitchingMessages = TLoggerMessages<
    MessageNamespace._004_FEATURE_CODEC_SWITCHING,
    typeof featureCodecSwitchingMessages
>;

export {featureCodecSwitchingMessages};
