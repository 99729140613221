import {LifecycleStatus} from '../Constants/LifecycleStatus';
import {LifecycleStatusMachineEventType} from '../Constants/LifecycleStatusMachineEventType';

const lifecycleStatusMachine = (
    prevLifecycleStatus: LifecycleStatus,
    eventType: LifecycleStatusMachineEventType,
): LifecycleStatus => {
    switch (prevLifecycleStatus) {
        case LifecycleStatus.DOWN:
            switch (eventType) {
                case LifecycleStatusMachineEventType.MANIFEST_REQUEST:
                    return LifecycleStatus.UP;
                default:
                    return prevLifecycleStatus;
            }

        case LifecycleStatus.TEARING_DOWN:
            switch (eventType) {
                case LifecycleStatusMachineEventType.TEAR_DOWN_END:
                    return LifecycleStatus.DOWN;
                default:
                    return prevLifecycleStatus;
            }

        case LifecycleStatus.UP:
            switch (eventType) {
                case LifecycleStatusMachineEventType.TEAR_DOWN_START:
                    return LifecycleStatus.TEARING_DOWN;
                default:
                    return prevLifecycleStatus;
            }

        default:
            return prevLifecycleStatus;
    }
};

export {lifecycleStatusMachine};
