import {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {logger} from '@Logger/logger';
import type {Period} from '@Shared/Models/Period';
import type {Variant} from '@Shared/Models/Variant';
import {hasElements} from '@Shared/Util/hasElements';
import {throwPlayerError} from '@Shared/Util/throwPlayerError';
import type {ConfigDrm} from '@State/Stores/Config/Models/ConfigDrm';

import {FeatureCodecFilteringMessageId} from '../Constants/FeatureCodecFilteringMessageId';
import type {TIsCodecSupported} from '../Types/TIsCodecSupported';

import {isCodecSupported} from './isCodecSupported';
import {isCodecSupportedViaRegexMatch} from './isCodecSupportedViaRegexMatch';
import {MimeType} from './MimeType';

interface ICreateCodecsFiltererReturnType {
    filterCodecsBySupport: (periods: Period[], configDrm: ConfigDrm) => Period[];
    resetCodecFilterer: () => void;
}

interface IDependencies {
    isCodecSupported: TIsCodecSupported;
}

/**
 * Creates and returns two closed over functions for checking the codec support of new periods
 * coming into the player.
 *
 * A local cache of "processed periods" is maintained within closure to
 * ensure the minimal amount of iteration is performed on each manifest update.
 */

const createCodecFilterer = (dependencies: IDependencies = {isCodecSupported}): ICreateCodecsFiltererReturnType => {
    let processedPeriods: Record<string, true> = {};
    let supportedCodecs: Record<string, boolean> = {};
    /**
     * Receives the latest list of periods, and returns an updated list of periods with any unsupported variants
     * within a period filtered out.
     *
     * Mutates the data passed in for performance reasons.
     */

    const filterCodecsBySupport = (periods: Period[], configDrm: ConfigDrm): Period[] => {
        let isPristine = true;

        // Perf: Reverse through the periods, logic being that newer ones will always be inserted at
        // the end / live edge. It is possible that new periods could be inserted at any arbitrary point,
        // but we accept that risk in favour of the performance benefits of this approach.

        for (let i = periods.length - 1; i >= 0; i--) {
            const period = periods[i];

            if (processedPeriods[period.id]) {
                // We have already processed this period (and all periods before it), so stop
                // iteration here.

                break;
            }

            // Check each variant within the period
            period.variants = period.variants.filter(variant => {
                // Verify codecs
                const supportsAudio = checkCodecFromCache(variant, configDrm, MimeType.AUDIO);

                const supportsVideo = checkCodecFromCache(variant, configDrm, MimeType.VIDEO);

                if (!supportsAudio) {
                    logger.warn(
                        MessageNamespace._020_FEATURE_CODEC_FILTERING,
                        FeatureCodecFilteringMessageId._000_UNSUPPORTED_AUDIO_CODEC,
                        variant.audioCodec,
                        variant.periodId,
                        variant.variantIndex,
                    );
                }

                if (!supportsVideo) {
                    logger.warn(
                        MessageNamespace._020_FEATURE_CODEC_FILTERING,
                        FeatureCodecFilteringMessageId._001_UNSUPPORTED_VIDEO_CODEC,
                        variant.videoCodec,
                        variant.periodId,
                        variant.variantIndex,
                    );
                }

                const isPlayable = supportsAudio && supportsVideo;

                if (!isPlayable) isPristine = false;

                return isPlayable;
            }, []);

            // Check the period has at least one variant

            if (!hasElements(period.variants)) {
                throwPlayerError(
                    MessageNamespace._020_FEATURE_CODEC_FILTERING,
                    FeatureCodecFilteringMessageId._002_NO_PLAYABLE_VARIANTS,
                    period,
                );
            }

            // Mark period as processed

            processedPeriods[period.id] = true;
        }

        if (isPristine) {
            logger.log(
                MessageNamespace._020_FEATURE_CODEC_FILTERING,
                FeatureCodecFilteringMessageId._003_ALL_CODECS_SUPPORTED,
            );
        }

        return periods;
    };

    /**
     * Function that checks if the codec is already supported via cache and returns it.
     * If not, then proceeds to verify if the codec is supported with help of
     * standard check (that uses MediaSource.isTypeSupported)
     * or regex based simple check (provided it is enabled) and logs a message
     */
    const checkCodecFromCache = (variant: Variant, configDrm: ConfigDrm, mimeType: MimeType) => {
        const {audioMime, videoMime, periodId, variantIndex} = variant;

        const mimeUnderTest = mimeType === MimeType.AUDIO ? audioMime : videoMime;

        const checkCodecSupported = (): boolean => {
            // In case of Tizen-legacy, it is known that it has fake implementation for
            // MediaSource.isTypeSupported, and the standard isCodecSupported will always return true
            // and would never go into regex based check, so in order to handle this edge case
            // the standard isCodecSupported will be skipped by setting canUseStandardCodecSupported
            // flag to false in tizen-legacy profile
            let supportsCodec = configDrm.canUseStandardCodecSupported
                ? dependencies.isCodecSupported(mimeUnderTest)
                : false;

            if (!supportsCodec && configDrm.canUseRegexForCodecSupported) {
                supportsCodec = isCodecSupportedViaRegexMatch(mimeUnderTest);
                logger.log(
                    MessageNamespace._020_FEATURE_CODEC_FILTERING,
                    FeatureCodecFilteringMessageId._004_CODEC_SUPPORTED_VIA_REGEX_MATCH,
                    mimeUnderTest,
                    periodId,
                    variantIndex,
                );
            }

            return supportsCodec;
        };

        return supportedCodecs[mimeUnderTest] ?? (supportedCodecs[mimeUnderTest] = checkCodecSupported());
    };

    /**
     * Resets the `processedPeriods` and `supportedCodecs` cache (e.g. in response to a new manifest).
     */

    const resetCodecFilterer = () => {
        processedPeriods = {};
        supportedCodecs = {};
    };

    return {
        filterCodecsBySupport,
        resetCodecFilterer,
    };
};

export type {IDependencies};
export {createCodecFilterer};
