import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';

import {FeatureStartupMessageId} from './FeatureStartupMessageId';

const featureStartupMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {},
    [MessageType.WARNING]: {
        [FeatureStartupMessageId._000_NEW_DURATION_LESS_THAN_BUFFERED_RANGES_END]: (
            newDuration: number,
            bufferedRangesEnd: number,
        ): string =>
            `The new duration (${newDuration}s) is less than the current buffered ranges end ` +
            `(${bufferedRangesEnd}s). Initiating removal of buffered ranges.`,
    },
    [MessageType.ERROR]: {},
};

export type TFeatureStartupMessages = TLoggerMessages<
    MessageNamespace._028_FEATURE_STARTUP,
    typeof featureStartupMessages
>;

export {featureStartupMessages};
